<template>
    <ValidationObserver tag="form" ref="observer" @submit.prevent>
        <div class="wizard-starting__page">
            <div class="wizard-starting__logo">
                <img src="../../../assets/img/wikiworks-logo.png" alt="Wikiworks">
            </div>
            <div class="wizard-starting__row">
                <div class="wizard-starting__col wizard-starting__col_left">
                    <div class="wizard-starting__block">
                        <div>
                            <h3 class="wizard-starting__title wizard-starting__title_has-subtitle">
                                Порядок начинается с определения правил игры.
                            </h3>
                            <p class="wizard-starting__subtitle">
                                Обычно его фиксируют в виде регламентов для типовых, рутинных операций.
                            </p>
                            <p class="wizard-starting__text wizard-starting__text_before-subtitle">
                                Давайте создадим ваш первый регламент в WikiWorks? Если у вас есть готовый текст —
                                просто скопируйте его. Если нет — зафиксируйте первое пришедшее на ум правило, которое
                                хотите донести до своих
                                сотрудников.
                            </p>

                            <ValidationProvider
                                rules="required|max_length:255"
                                vid="material_title"
                                v-slot="provider"
                                slim
                            >
                                <div class="wizard-starting__form-group"
                                     :class="{'is-invalid': provider.errors.length > 0}">
                                    <label for="material_title" class="wizard-starting__form-group-label">
                                        Название материала
                                    </label>
                                    <input
                                        id="material_title"
                                        name="material_title"
                                        type="text"
                                        class="wizard-starting__form-group-input"
                                        v-model="form.title"
                                        maxlength="255"
                                    >
                                    <div id="material_title-error"
                                         class="wizard-starting__form-group-error animated fadeInDown"
                                         v-if="provider.errors.length > 0"
                                    >
                                        {{provider.errors[0]}}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider rules="required" vid="material_text" v-slot="provider" slim>
                                <div
                                    class="wizard-starting__form-group"
                                    :class="{'is-invalid': provider.errors.length > 0}"
                                >
                                    <label class="wizard-starting__form-group-label">Текст</label>
                                    <wysiwyg v-model="form.text" :data="form.text"/>
                                    <div id="material_text-error"
                                         class="wizard-starting__form-group-error animated fadeInDown"
                                         v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                                    </div>
                                    <p class="wizard-starting__text">
                                        Позже вы сможете настроить права доступа и отредактировать атрибуты материала
                                    </p>
                                </div>
                            </ValidationProvider>
                            <div class="wizard-starting__btn-group">
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_prev"
                                    @mouseup="onPrev"
                                >
                                    Назад
                                </button>
                                <button
                                    class="wizard-starting__btn wizard-starting__btn_next"
                                    @click="onNext"
                                >
                                    Далее
                                </button>
                            </div>
                            <button class="v-btn v-btn--skip" @click="onSkip">
                                Пропустить
                                <span class="v-btn-icon">
                                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L4 4" stroke="black" stroke-linecap="round"/>
                                        <path d="M4 4L1 7" stroke="black" stroke-linecap="round"/>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div>
                            <progress-bar-steps :step="4"></progress-bar-steps>
                        </div>
                    </div>
                </div>
                <div class="wizard-starting__col wizard-starting__col_right">
                    <svg width="960" height="937" viewBox="0 0 960 937" fill="none" xmlns="http://www.w3.org/2000/svg"
                         preserveAspectRatio="xMidYMid slice">
                        <rect width="960" height="937" fill="#F2F2F2"/>
                        <rect y="574" width="960" height="363" fill="#FBC04F"/>
                        <g clip-path="url(#clip0)">
                            <path
                                d="M199.642 698.646C185.838 704.382 206.116 707.433 213.934 709.508C219.92 711.094 236.288 713.047 242.396 713.901C249.848 714.878 267.682 715.61 275.012 714.023C318.499 704.382 351.847 711.216 401.076 709.508C404.618 709.386 418.544 703.894 421.72 702.429C441.876 693.032 493.547 695.839 522.498 697.792C542.531 705.724 557.923 706.823 568.306 708.898C598.967 715 660.533 712.437 670.306 710.362C687.285 706.579 694.615 707.921 704.876 702.429C709.029 700.233 724.42 697.548 720.145 688.028C718.923 685.343 693.882 678.875 690.95 678.387C668.595 675.214 655.891 673.383 633.537 670.21C604.83 666.06 575.88 662.033 546.929 663.253C512.237 664.718 477.667 673.627 443.219 669.966C428.927 668.501 414.513 664.84 400.343 667.159C389.349 668.989 357.589 671.674 352.458 671.674C345.129 671.674 338.288 674.97 331.447 677.777C309.337 686.564 285.395 689.005 261.819 689.615C252.291 689.859 242.518 689.737 232.99 690.347C226.883 690.713 220.897 691.69 215.033 693.276C211.98 693.886 202.207 699.378 199.642 698.646Z"
                                fill="#EF9116"/>
                            <path
                                d="M550.96 264.539C548.273 267.346 546.44 271.007 546.807 274.912C547.295 279.428 550.716 283.089 552.182 287.483C552.915 289.924 553.037 292.609 553.648 295.049C554.258 297.612 555.724 300.053 558.167 301.03C559.633 301.64 561.221 301.518 562.687 301.396C577.956 300.053 593.104 305.789 608.129 305.789C606.296 297.734 604.22 278.94 601.899 271.007C600.189 265.149 598.112 258.803 592.859 255.385C588.217 252.334 582.11 252.456 576.613 253.433C567.818 255.141 557.434 257.948 550.96 264.539Z"
                                fill="#A6D0F4"/>
                            <path
                                d="M637.69 297.246H376.034C371.148 297.246 367.117 301.274 367.117 306.277V669.844C367.117 674.848 371.148 678.875 376.034 678.875H637.69C642.577 678.875 646.608 674.848 646.608 669.844V306.277C646.608 301.274 642.699 297.246 637.69 297.246Z"
                                fill="#FBAF42"/>
                            <path
                                d="M621.932 317.994H391.914C389.593 317.994 387.639 319.946 387.639 322.387V653.735C387.639 656.176 389.593 658.128 391.914 658.128H621.932C624.253 658.128 626.208 656.176 626.208 653.735V322.387C626.208 319.946 624.253 317.994 621.932 317.994Z"
                                fill="white"/>
                            <path
                                d="M552.426 286.75H531.537C531.537 278.33 524.697 271.373 516.146 271.373H497.456C489.028 271.373 482.065 278.207 482.065 286.75H461.298C455.923 286.75 451.526 291.144 451.526 296.514V332.029H562.076V296.392C562.198 291.022 557.801 286.75 552.426 286.75ZM506.251 290.412C503.198 290.412 500.754 287.971 500.754 284.92C500.754 281.869 503.198 279.428 506.251 279.428C509.305 279.428 511.748 281.869 511.748 284.92C511.748 287.971 509.305 290.412 506.251 290.412Z"
                                fill="#82A7C3"/>
                            <path
                                d="M480.11 372.181C510.405 372.181 540.699 373.035 570.871 370.472C572.948 370.35 572.948 367.421 570.871 367.177C540.699 364.492 510.283 365.469 480.11 365.469C475.713 365.346 475.713 372.181 480.11 372.181Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M475.346 391.342C494.891 393.66 514.436 393.416 534.103 393.538C543.631 393.538 553.281 393.782 562.809 393.538C571.727 393.294 582.11 393.416 590.661 390.853C592.004 390.487 592.004 388.535 590.661 388.046C582.598 385.605 572.582 385.605 564.153 385.361C554.136 385.117 544.12 385.361 534.103 385.361C514.436 385.483 494.891 385.117 475.346 387.558C473.025 387.924 473.025 391.097 475.346 391.342Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M476.079 411.601C489.272 413.309 502.831 412.699 516.146 412.699C528.606 412.577 542.165 414.164 554.136 410.991C556.335 410.38 556.335 407.207 554.136 406.597C542.043 403.424 528.484 405.011 516.146 404.888C502.953 404.766 489.272 404.278 476.079 405.987C472.414 406.475 472.414 411.113 476.079 411.601Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M431.37 371.083C422.82 370.96 414.879 382.921 415.368 390.731C416.101 401.471 427.706 406.841 437.234 404.522C447.128 402.082 457.39 392.074 455.191 381.212C453.603 372.913 442.487 367.665 434.913 371.815C433.569 372.547 434.791 374.622 436.134 373.89C438.944 372.303 442.609 373.035 445.54 374.012C451.037 375.72 453.603 380.602 452.748 386.216C451.648 393.416 443.097 400.007 436.501 401.593C428.439 403.424 418.544 399.275 418.544 389.877C418.544 384.873 425.507 374.744 431.37 374.622C433.569 374.5 433.569 371.083 431.37 371.083Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M418.788 369.984C421.964 373.767 424.53 377.917 427.461 381.944C429.049 384.263 431.859 389.389 434.424 390.609C436.379 391.464 436.99 390.609 438.333 389.145C441.754 384.995 443.952 379.381 446.518 374.744C450.549 367.421 456.657 359.732 458.855 351.677C459.1 350.823 458 350.213 457.267 350.823C452.503 355.217 449.694 361.929 446.64 367.543C443.83 372.547 441.143 377.673 438.333 382.676C437.6 383.897 436.867 385.972 435.89 386.948C435.646 387.07 435.402 388.046 435.035 388.046C436.623 387.802 433.081 384.019 432.836 383.775C428.805 378.527 425.385 372.791 421.232 367.665C419.766 365.712 417.323 368.275 418.788 369.984Z"
                                fill="#E54036"/>
                            <path
                                d="M480.11 442.844C510.405 442.844 540.699 443.698 570.871 441.135C572.948 441.013 572.948 438.084 570.871 437.84C540.699 435.155 510.283 436.132 480.11 436.132C475.713 436.132 475.713 442.844 480.11 442.844Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M475.346 462.005C494.891 464.323 514.436 464.079 534.103 464.201C543.631 464.201 553.281 464.445 562.809 464.201C571.727 463.957 582.11 464.079 590.661 461.516C592.004 461.15 592.004 459.198 590.661 458.709C582.598 456.269 572.582 456.269 564.153 456.024C554.136 455.78 544.12 456.024 534.103 456.024C514.436 456.147 494.891 455.78 475.346 458.221C473.025 458.587 473.025 461.761 475.346 462.005Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M476.079 482.386C489.272 484.095 502.831 483.484 516.146 483.484C528.606 483.362 542.165 484.949 554.136 481.776C556.335 481.166 556.335 477.992 554.136 477.382C542.043 474.209 528.484 475.796 516.146 475.674C502.953 475.552 489.272 475.063 476.079 476.772C472.414 477.138 472.414 481.898 476.079 482.386Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M431.37 441.746C422.82 441.624 414.879 453.584 415.368 461.395C416.101 472.134 427.706 477.504 437.234 475.185C447.128 472.745 457.39 462.737 455.191 451.875C453.603 443.576 442.487 438.328 434.913 442.478C433.569 443.21 434.791 445.285 436.134 444.553C438.944 442.966 442.609 443.698 445.54 444.675C451.037 446.383 453.603 451.265 452.748 456.879C451.648 464.08 443.097 470.67 436.501 472.256C428.439 474.087 418.544 469.938 418.544 460.54C418.544 455.536 425.507 445.407 431.37 445.285C433.569 445.163 433.569 441.746 431.37 441.746Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M418.788 440.769C421.964 444.552 424.53 448.702 427.461 452.729C429.049 455.048 431.859 460.174 434.424 461.394C436.379 462.249 436.99 461.394 438.333 459.93C441.754 455.78 443.952 450.166 446.518 445.529C450.549 438.206 456.657 430.517 458.855 422.463C459.1 421.608 458 420.998 457.267 421.608C452.503 426.002 449.694 432.714 446.64 438.328C443.83 443.332 441.143 448.458 438.333 453.462C437.6 454.682 436.867 456.757 435.89 457.733C435.646 457.855 435.402 458.832 435.035 458.832C436.623 458.587 433.081 454.804 432.836 454.56C428.805 449.312 425.385 443.576 421.232 438.45C419.766 436.498 417.323 438.938 418.788 440.769Z"
                                fill="#E54036"/>
                            <path
                                d="M480.11 513.629C510.405 513.629 540.699 514.483 570.871 511.92C572.948 511.798 572.948 508.869 570.871 508.625C540.699 505.94 510.283 506.916 480.11 506.916C475.713 506.794 475.713 513.629 480.11 513.629Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M475.346 532.789C494.891 535.108 514.436 534.864 534.103 534.986C543.631 534.986 553.281 535.23 562.809 534.986C571.727 534.742 582.11 534.864 590.661 532.301C592.004 531.935 592.004 529.982 590.661 529.494C582.598 527.053 572.582 527.053 564.153 526.809C554.136 526.565 544.12 526.809 534.103 526.809C514.436 526.931 494.891 526.565 475.346 529.006C473.025 529.372 473.025 532.545 475.346 532.789Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M476.079 553.049C489.272 554.757 502.831 554.147 516.146 554.147C528.606 554.025 542.165 555.612 554.136 552.438C556.335 551.828 556.335 548.655 554.136 548.045C542.043 544.872 528.484 546.458 516.146 546.336C502.953 546.214 489.272 545.726 476.079 547.435C472.414 547.923 472.414 552.56 476.079 553.049Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M431.37 512.408C422.82 512.286 414.879 524.246 415.368 532.057C416.101 542.797 427.706 548.167 437.234 545.848C447.128 543.407 457.39 533.4 455.191 522.538C453.603 514.239 442.487 508.991 434.913 513.14C433.569 513.873 434.791 515.947 436.134 515.215C438.944 513.629 442.609 514.361 445.54 515.337C451.037 517.046 453.603 521.928 452.748 527.542C451.648 534.742 443.097 541.332 436.501 542.919C428.439 544.75 418.544 540.6 418.544 531.203C418.544 526.199 425.507 516.069 431.37 515.947C433.569 515.947 433.569 512.53 431.37 512.408Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M480.11 584.292C510.405 584.292 540.699 585.146 570.871 582.583C572.948 582.461 572.948 579.532 570.871 579.288C540.699 576.603 510.283 577.579 480.11 577.579C475.713 577.579 475.713 584.292 480.11 584.292Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M475.346 603.452C494.891 605.771 514.436 605.527 534.103 605.649C543.631 605.649 553.281 605.893 562.809 605.649C571.727 605.405 582.11 605.527 590.661 602.964C592.004 602.598 592.004 600.645 590.661 600.157C582.598 597.716 572.582 597.716 564.153 597.472C554.136 597.228 544.12 597.472 534.103 597.472C514.436 597.594 494.891 597.228 475.346 599.669C473.025 600.035 473.025 603.208 475.346 603.452Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M476.079 623.834C489.272 625.542 502.831 624.932 516.146 624.932C528.606 624.81 542.165 626.397 554.136 623.224C556.335 622.613 556.335 619.44 554.136 618.83C542.043 615.657 528.484 617.243 516.146 617.121C502.953 616.999 489.272 616.511 476.079 618.22C472.414 618.586 472.414 623.346 476.079 623.834Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M431.37 583.193C422.82 583.071 414.879 595.032 415.368 602.842C416.101 613.582 427.706 618.952 437.234 616.633C447.128 614.192 457.39 604.185 455.191 593.323C453.603 585.024 442.487 579.776 434.913 583.926C433.569 584.658 434.791 586.733 436.134 586C438.944 584.414 442.609 585.146 445.54 586.122C451.037 587.831 453.603 592.713 452.748 598.327C451.648 605.527 443.097 612.118 436.501 613.704C428.439 615.535 418.544 611.385 418.544 601.988C418.544 596.984 425.507 586.855 431.37 586.733C433.569 586.611 433.569 583.193 431.37 583.193Z"
                                fill="#BBBBBA"/>
                            <path
                                d="M610.083 305.911C611.427 307.986 613.381 302.738 614.969 302.86C615.702 305.667 615.702 310.061 617.168 312.624C619.489 316.773 622.054 312.135 621.81 309.206C621.688 307.498 622.543 300.053 621.199 297.612C615.092 286.628 608.984 304.203 610.083 305.911Z"
                                fill="#FDC28B"/>
                            <path
                                d="M626.818 248.673C622.299 244.523 598.723 250.259 589.439 252.578C584.675 253.798 582.354 252.822 583.453 258.07C584.308 262.097 590.172 266.125 592.981 269.298C593.47 269.908 594.081 270.518 594.936 270.762C595.913 271.007 596.89 270.762 597.868 270.396C602.387 268.932 617.168 262.341 617.657 262.708C618.145 263.074 614.969 284.797 613.503 294.927C613.259 296.514 622.054 298.1 622.177 297.246C624.864 288.093 627.185 282.479 628.406 274.18C629.506 265.637 629.994 251.602 626.818 248.673Z"
                                fill="#A6D0F4"/>
                            <path
                                d="M491.104 348.016C489.883 349.358 488.661 350.945 488.661 352.776C489.761 353.264 490.86 352.409 491.593 351.555C492.326 350.579 492.692 349.48 493.67 348.87C495.99 347.284 499.289 349.48 501.976 348.87C504.297 348.382 505.641 346.185 506.496 343.988C507.84 340.327 505.396 336.056 501.121 337.398C497.456 338.374 493.547 345.209 491.104 348.016Z"
                                fill="#FDC28B"/>
                            <path
                                d="M536.668 281.136C527.262 297.246 504.297 328.123 499.777 337.154C499.777 337.154 505.152 344.843 506.984 343.012C516.268 333.737 527.14 323.363 534.836 312.624C544.12 299.565 550.594 296.392 558.656 280.892C560.855 276.743 559.023 260.755 553.159 262.586C547.907 264.172 539.233 276.743 536.668 281.136Z"
                                fill="#A6D0F4"/>
                            <path
                                d="M559.878 263.806C560.488 267.223 561.832 270.518 563.664 273.448C564.764 270.763 566.596 268.444 568.795 266.613C570.139 268.2 571.482 269.786 572.826 271.373C573.803 269.786 574.78 268.2 575.758 266.613C577.346 268.322 578.323 270.641 578.567 272.959C580.644 271.617 581.865 269.42 583.087 267.223C584.309 265.149 585.408 262.952 585.774 260.511C586.141 258.07 585.408 255.385 583.331 254.043C582.476 253.432 581.377 253.188 580.277 252.822C578.323 252.334 576.368 251.724 574.414 251.724C566.963 251.48 558.29 255.141 559.878 263.806Z"
                                fill="#98C4E5"/>
                            <path
                                d="M579.544 235.126C574.414 230.244 564.397 232.563 561.954 239.276L558.167 242.449C557.556 248.917 559.022 255.629 562.198 261.243C564.519 265.393 568.917 269.42 573.559 268.566C578.811 267.468 581.01 261.243 582.11 255.873C583.698 248.795 584.797 240.252 579.544 235.126Z"
                                fill="#FDC28B"/>
                            <path
                                d="M558.045 244.767C560 245.256 568.184 242.815 570.383 242.937C573.192 242.937 576.246 243.181 578.078 245.256C578.934 246.232 579.3 247.33 579.666 248.551C580.399 250.87 581.132 253.066 581.987 255.385C586.019 253.31 586.385 250.381 585.896 245.866C585.53 243.181 585.041 238.055 583.209 235.98C580.888 233.173 577.468 230.61 572.826 230.244C567.084 229.634 558.9 230.976 554.625 234.76C551.693 237.079 552.67 243.303 558.045 244.767Z"
                                fill="#53628A"/>
                            <path
                                d="M583.453 252.212C583.453 251.48 583.087 246.598 580.155 248.429C579.3 248.917 579.178 250.26 579.3 251.358C579.666 252.09 579.911 255.996 580.766 256.24C583.331 257.338 583.453 253.433 583.453 252.212Z"
                                fill="#FDC28B"/>
                            <path
                                d="M572.826 259.046C570.627 260.023 566.474 260.633 564.397 259.901C564.886 262.22 566.962 264.172 569.283 263.928C571.604 263.806 573.559 261.243 572.826 259.046Z"
                                fill="white"/>
                            <path
                                d="M639.645 698.036C635.125 699.012 636.469 701.575 636.957 702.186C647.463 703.04 651.616 698.036 651.616 698.036C651.86 699.135 651.86 700.965 652.105 701.942C653.082 702.186 654.059 701.942 654.792 701.331C655.769 699.135 656.38 697.304 656.991 694.985C657.235 693.765 655.158 689.615 654.914 688.395C654.67 687.174 649.661 688.639 649.417 688.761C648.562 689.493 648.318 696.205 639.645 698.036Z"
                                fill="#E54036"/>
                            <path
                                d="M666.03 693.398C661.877 694.253 662.488 696.816 662.854 697.426C672.504 698.158 676.413 693.52 676.413 693.643C676.658 694.619 676.658 696.327 676.78 697.304C677.635 697.548 678.612 697.304 679.345 696.816C680.2 694.863 680.811 693.032 681.422 690.958C681.666 689.859 679.712 686.076 679.467 684.977C679.345 683.879 674.581 685.222 674.459 685.344C673.604 685.71 673.97 691.69 666.03 693.398Z"
                                fill="#E54036"/>
                            <path
                                d="M648.073 689.859C650.516 690.591 652.837 690.835 655.891 689.127C651.494 673.383 656.136 652.392 647.951 632.865C650.15 625.542 664.564 605.649 668.229 597.716C663.953 611.751 659.312 622.369 660.289 633.109C660.777 638.967 667.985 669.478 673.115 686.686C673.604 688.394 680.2 685.954 680.2 685.954C673.848 665.328 683.132 651.049 673.237 632.621C670.428 627.373 679.711 616.267 684.476 607.236C688.507 599.425 692.293 591.858 690.583 583.193C688.995 574.772 685.819 570.379 685.819 570.379C677.39 567.083 658.09 563.422 652.715 572.087C647.463 580.508 637.079 618.708 635.369 628.349C631.704 648.12 643.554 662.277 648.073 689.859Z"
                                fill="#53628A"/>
                            <path
                                d="M704.998 547.312C704.509 549.509 699.501 554.513 696.691 556.587C690.705 561.103 686.308 563.3 679.467 567.571C677.635 564.276 673.97 563.056 677.146 560.859C680.322 558.662 694.37 547.068 694.248 544.993C694.126 543.041 686.552 535.108 684.109 532.423C677.146 524.246 674.337 521.927 675.925 511.309C676.535 507.526 679.345 503.377 683.62 505.94C686.674 507.77 688.873 513.384 690.705 516.313C696.202 525.589 705.486 545.237 704.998 547.312Z"
                                fill="#E54036"/>
                            <path
                                d="M670.305 493.735C669.939 491.539 668.595 489.342 666.396 488.61C664.198 487.877 659.678 493.613 659.678 493.613C661.022 496.054 660.289 499.471 658.212 501.18C657.235 502.034 655.891 502.767 656.013 504.109C656.135 505.329 657.357 505.94 658.578 506.306C661.388 507.282 664.564 508.136 667.007 506.55C668.839 505.207 669.45 502.767 669.939 500.448C670.305 498.251 670.672 495.932 670.305 493.735Z"
                                fill="#FDC28B"/>
                            <path
                                d="M583.82 487.633C583.331 487.267 582.842 486.901 582.232 486.535C579.788 485.07 577.223 483.728 574.536 482.507C574.169 482.385 573.681 482.141 573.314 482.141C572.826 482.141 572.459 482.507 572.337 482.873C572.337 483.239 572.459 483.484 572.703 483.728C573.558 484.826 574.536 485.68 575.757 486.291C576.246 486.535 576.734 486.779 577.101 487.145C577.467 487.511 577.59 488.121 577.59 488.609C577.834 490.928 577.59 493.613 578.933 495.566C579.666 496.542 580.766 497.274 581.865 498.007C583.575 499.105 585.408 500.204 587.362 500.448C594.325 501.302 585.163 488.731 583.82 487.633Z"
                                fill="#FDC28B"/>
                            <path
                                d="M645.63 503.133C641.11 504.597 619.244 510.333 617.534 509.357C607.517 503.621 599.333 497.397 589.316 491.539C587.728 490.684 584.186 499.593 586.018 500.936C595.79 508.258 602.875 516.802 614.358 520.829C623.397 524.002 643.431 518.876 644.164 519.242C645.019 519.853 643.187 524.368 642.82 526.443C639.156 544.994 653.814 541.21 652.837 571.843C664.442 573.43 674.947 574.528 685.941 570.134C684.475 558.784 681.543 555.367 681.788 543.773C681.91 539.624 685.208 534.254 686.185 530.104C686.796 527.297 687.773 524.612 688.262 521.805C688.873 517.9 688.384 513.872 686.918 510.211C685.086 505.94 681.788 502.156 677.268 500.814C665.297 497.641 656.624 499.105 645.63 503.133Z"
                                fill="#E54036"/>
                            <path
                                d="M654.547 484.338C654.425 486.535 654.547 488.854 655.647 490.806C657.235 493.613 660.533 494.956 663.709 495.2C672.504 495.688 680.078 487.633 681.788 478.968C683.376 470.547 677.757 458.831 667.374 461.882C659.311 464.201 654.914 477.015 654.547 484.338Z"
                                fill="#FDC28B"/>
                            <path
                                d="M668.717 496.664C667.129 502.767 665.908 509.845 669.572 514.971C670.55 516.313 671.771 517.412 672.382 518.754C674.092 522.416 671.649 527.175 673.848 530.47C674.825 531.935 676.535 532.789 678.001 533.521C681.299 535.108 684.72 536.695 688.384 537.183C692.049 537.549 696.08 536.45 698.279 533.521C700.844 530.104 700.233 525.223 698.89 521.073C697.546 516.924 695.469 512.896 695.591 508.625C695.714 503.865 698.401 499.105 696.813 494.712C696.08 492.515 694.37 490.806 693.393 488.61C691.683 484.826 689.85 482.385 690.094 478.236C690.827 467.984 672.016 451.997 660.899 459.685C654.181 464.323 657.846 468.839 662.976 472.256C669.084 476.161 671.16 487.023 668.717 496.664Z"
                                fill="#1D4B7C"/>
                            <path
                                d="M656.135 482.996C655.524 484.338 656.257 487.877 657.601 488.488C658.7 488.976 659.556 487.389 660.288 485.314L656.135 482.996Z"
                                fill="white"/>
                            <path
                                d="M427.461 608.7C416.345 615.657 408.894 629.814 403.519 639.577C407.428 627.007 408.405 608.944 408.649 596.008C395.701 606.504 392.769 623.834 390.815 637.625C391.181 625.42 388.249 610.531 383.363 598.815C380.92 610.165 378.477 621.515 379.821 632.987C381.164 643.605 382.508 656.663 394.724 663.742C402.175 658.372 411.337 648.608 415.368 641.53C420.987 631.767 425.629 621.759 427.461 608.7Z"
                                fill="#82A7C3"/>
                            <path
                                d="M389.471 648.12C386.173 637.625 384.341 626.763 383.852 615.779C383.852 615.413 383.241 615.413 383.241 615.779C383.73 626.885 385.684 637.625 389.105 648.243C389.105 648.487 389.593 648.365 389.471 648.12Z"
                                fill="white"/>
                            <path
                                d="M395.823 648.731C398.388 637.503 400.587 626.275 402.175 614.803C402.175 614.436 401.564 614.192 401.564 614.681C399.854 626.031 397.9 637.381 395.457 648.609C395.335 648.853 395.701 648.975 395.823 648.731Z"
                                fill="white"/>
                            <path
                                d="M403.519 650.439C410.726 641.774 416.712 631.034 418.91 619.928C419.033 619.562 418.422 619.44 418.422 619.806C415.001 630.912 410.604 641.042 403.275 650.195C403.03 650.317 403.275 650.561 403.519 650.439Z"
                                fill="white"/>
                            <path
                                d="M351.847 606.748C366.628 614.559 366.872 625.298 372.98 635.794C370.048 625.054 372.247 614.314 375.057 603.575C382.875 614.192 384.707 623.834 383.73 635.794C385.196 623.102 390.815 612.85 397.045 600.89C398.388 603.941 399.121 607.114 399.488 610.287C400.587 620.539 398.877 633.109 394.357 642.751C392.158 647.51 391.181 653.734 387.15 657.884C382.63 662.522 381.287 661.667 375.423 658.006C366.75 652.636 361.253 643.849 357.466 636.404C355.268 632.133 353.802 624.444 352.824 619.806C352.214 617.121 351.847 613.582 351.847 606.748Z"
                                fill="#E54036"/>
                            <path
                                d="M356.489 618.708C358.932 628.593 363.086 640.432 371.392 647.144C371.759 647.388 372.125 647.022 371.881 646.656C369.438 643.605 366.628 641.042 364.552 637.625C361.009 631.767 358.688 625.176 356.856 618.586C356.856 618.22 356.489 618.342 356.489 618.708Z"
                                fill="white"/>
                            <path
                                d="M380.187 645.069C380.187 640.31 379.699 635.672 379.088 631.034C378.355 625.787 377.989 619.684 376.034 614.803C375.912 614.559 375.545 614.559 375.545 614.803C375.912 619.806 377.5 624.81 378.111 629.692C378.844 634.818 379.454 639.944 379.454 645.069C379.577 645.436 380.187 645.436 380.187 645.069Z"
                                fill="white"/>
                            <path
                                d="M387.272 647.022C389.96 642.629 391.548 637.869 392.769 632.865C393.991 627.739 395.334 622.491 396.434 617.366C396.556 616.999 395.945 616.877 395.823 617.244C392.891 627.129 391.914 637.625 386.784 646.778C386.661 647.022 387.15 647.266 387.272 647.022Z"
                                fill="white"/>
                            <path
                                d="M399.487 694.375H374.69C367.239 694.375 361.253 688.395 361.253 680.95V651.904H412.802V680.95C412.925 688.395 406.817 694.375 399.487 694.375Z"
                                fill="#E4F5F9"/>
                            <path
                                d="M568.55 638.723C568.062 636.892 566.84 635.305 565.741 633.719C559.755 625.786 554.258 615.9 556.824 606.259C557.556 603.452 559.022 600.889 559.633 598.082C561.343 590.638 557.434 583.193 553.647 576.603C562.565 579.165 570.627 584.901 576.002 592.468C578.201 595.519 579.911 598.936 580.155 602.598C580.522 608.822 576.368 615.29 579.056 620.782C579.911 622.491 581.254 623.833 581.987 625.542C584.308 630.79 579.911 636.892 580.766 642.506C581.377 647.266 585.652 657.883 576.368 655.443C568.673 653.734 570.016 644.215 568.55 638.723Z"
                                fill="#FBAF42"/>
                            <path
                                d="M580.033 635.672C580.399 633.353 580.888 630.912 582.354 629.081C583.576 627.495 585.164 626.396 586.385 624.932C590.66 619.196 586.385 610.775 588.706 603.94C589.805 600.523 592.615 597.838 594.814 594.909C599.578 588.685 602.143 580.874 602.021 573.063C613.015 595.031 607.151 609.31 605.441 613.338C604.342 615.901 602.387 618.097 601.288 620.782C597.99 628.593 602.754 638.235 598.967 645.801C596.035 651.659 596.035 662.887 587.484 658.372C580.155 654.588 579.056 642.75 580.033 635.672Z"
                                fill="#2E85DE"/>
                            <path
                                d="M560.366 585.146C564.642 587.465 568.062 591.248 569.772 595.886C571.604 601.133 570.261 606.137 569.528 611.385C568.428 620.294 571.971 629.203 575.88 637.014C576.124 637.38 576.857 637.136 576.613 636.648C574.536 631.644 572.093 626.885 570.871 621.515C569.406 615.29 570.749 609.921 571.36 603.818C572.093 595.764 567.818 588.197 560.733 584.536C560.366 584.414 560 585.024 560.366 585.146Z"
                                fill="white"/>
                            <path
                                d="M591.149 641.774C589.683 632.743 596.157 625.054 596.89 616.267C597.134 613.826 596.89 611.141 597.623 608.822C598.478 606.259 600.433 604.063 601.776 601.622C603.609 598.205 604.952 594.421 605.319 590.516C605.319 590.028 604.464 589.906 604.342 590.394C603.364 595.642 601.532 600.157 598.722 604.673C596.157 608.822 596.524 612.484 595.913 617.121C594.814 625.542 588.95 633.109 590.538 641.896C590.66 642.384 591.271 642.14 591.149 641.774Z"
                                fill="white"/>
                            <path
                                d="M562.809 624.688C563.786 626.03 565.13 627.129 566.474 628.227C567.695 629.325 569.039 630.546 570.505 631.278C570.871 631.522 571.238 631.034 570.993 630.668C569.772 629.447 568.184 628.471 566.962 627.373C565.741 626.396 564.641 625.176 563.298 624.322C563.053 623.955 562.687 624.322 562.809 624.688Z"
                                fill="white"/>
                            <path
                                d="M559.755 612.849C561.099 614.314 562.565 615.534 564.031 616.999C565.374 618.219 566.596 619.562 568.062 620.538C568.428 620.782 568.795 620.294 568.55 619.928C567.451 618.463 565.863 617.365 564.519 616.145C563.175 614.924 561.71 613.582 560.244 612.483C559.999 612.117 559.511 612.605 559.755 612.849Z"
                                fill="white"/>
                            <path
                                d="M561.832 604.551C562.687 606.625 564.275 609.554 566.474 610.531C566.84 610.775 567.207 610.287 566.963 609.921C566.23 608.944 565.252 608.334 564.519 607.358C563.786 606.381 563.176 605.405 562.687 604.307C562.443 603.818 561.588 604.185 561.832 604.551Z"
                                fill="white"/>
                            <path
                                d="M561.954 598.815C562.687 599.547 563.42 600.157 564.031 600.889C564.641 601.5 565.252 602.232 566.107 602.72C566.352 602.842 566.718 602.598 566.596 602.232C566.107 601.5 565.374 600.889 564.764 600.279C564.031 599.547 563.298 598.937 562.565 598.204C562.076 597.838 561.465 598.327 561.954 598.815Z"
                                fill="white"/>
                            <path
                                d="M577.223 629.569C577.468 628.471 577.468 627.373 577.59 626.274C577.712 625.176 577.956 624.077 577.712 622.979C577.712 622.735 577.345 622.735 577.223 622.857C576.857 623.711 576.857 624.688 576.857 625.664C576.735 626.884 576.613 628.105 576.735 629.325C576.613 629.936 577.101 630.058 577.223 629.569Z"
                                fill="white"/>
                            <path
                                d="M574.536 619.074C574.536 618.219 574.658 617.365 574.902 616.633C575.025 615.9 575.391 615.168 575.513 614.436C575.635 614.07 575.147 613.826 574.902 614.192C574.047 615.534 573.925 617.487 573.925 619.074C573.925 619.44 574.536 619.44 574.536 619.074Z"
                                fill="white"/>
                            <path
                                d="M573.559 608.456C574.048 607.724 574.536 606.869 574.903 606.015C575.147 605.405 575.636 604.673 575.269 604.062C575.147 603.94 575.025 603.94 574.903 603.94C574.292 604.307 574.292 605.039 574.048 605.649C573.681 606.503 573.315 607.358 572.826 608.09C572.704 608.578 573.315 608.944 573.559 608.456Z"
                                fill="white"/>
                            <path
                                d="M574.536 598.937C574.902 598.083 575.391 597.106 575.391 596.252C575.391 595.886 574.902 595.764 574.658 596.008C574.169 596.74 573.925 597.838 573.681 598.693C573.681 599.181 574.414 599.303 574.536 598.937Z"
                                fill="white"/>
                            <path
                                d="M584.431 633.109C585.041 634.329 585.774 635.427 586.629 636.404C586.752 636.526 586.996 636.648 587.118 636.526C587.24 636.526 587.362 636.404 587.362 636.282C587.485 636.038 587.485 635.916 587.24 635.672C586.385 634.695 585.652 633.719 585.041 632.742C584.797 632.376 584.186 632.742 584.431 633.109Z"
                                fill="white"/>
                            <path
                                d="M587.118 628.349C587.362 629.081 587.851 630.057 588.706 630.179C588.95 630.179 589.195 629.935 589.073 629.691C588.706 629.081 588.095 628.837 587.851 628.227C587.729 627.739 586.996 627.861 587.118 628.349Z"
                                fill="white"/>
                            <path
                                d="M589.683 622.735C589.927 623.223 590.294 623.955 590.905 624.077C591.149 624.077 591.271 623.955 591.271 623.711C591.271 623.101 590.66 622.613 590.294 622.125C590.05 621.881 589.439 622.369 589.683 622.735Z"
                                fill="white"/>
                            <path
                                d="M590.294 614.192C590.661 614.802 590.905 615.657 591.516 615.901C591.638 615.901 591.882 615.901 591.882 615.657C592.004 614.924 591.394 614.314 591.027 613.826C590.783 613.338 590.05 613.826 590.294 614.192Z"
                                fill="white"/>
                            <path
                                d="M590.416 605.649C590.905 606.259 591.271 606.87 591.76 607.48C592.126 607.968 592.371 608.578 592.982 608.578C593.104 608.578 593.226 608.456 593.226 608.334C593.348 607.724 592.859 607.358 592.493 606.87C592.004 606.259 591.638 605.771 591.149 605.161C590.783 604.795 590.05 605.161 590.416 605.649Z"
                                fill="white"/>
                            <path
                                d="M593.958 601.011C594.325 601.377 594.691 601.743 595.058 602.232C595.424 602.598 595.546 602.964 596.035 602.964C596.279 602.964 596.524 602.842 596.524 602.476C596.402 601.988 596.035 601.743 595.791 601.499C595.424 601.133 595.058 600.767 594.569 600.401C594.081 600.035 593.47 600.645 593.958 601.011Z"
                                fill="white"/>
                            <path
                                d="M582.476 639.821C583.087 640.675 583.82 641.53 584.431 642.506C585.041 643.36 585.652 644.459 586.507 645.069C586.874 645.313 587.362 645.069 587.118 644.581C586.63 643.604 585.897 642.872 585.164 642.018C584.431 641.164 583.698 640.187 582.965 639.333C582.721 639.089 582.232 639.455 582.476 639.821Z"
                                fill="white"/>
                            <path
                                d="M571.116 638.112C571.849 638.601 572.459 639.089 573.192 639.577C573.803 640.065 574.414 640.675 575.147 640.797C575.391 640.797 575.513 640.675 575.513 640.431C575.147 639.821 574.414 639.333 573.803 638.967C573.07 638.479 572.337 637.868 571.604 637.38C571.116 637.136 570.749 637.868 571.116 638.112Z"
                                fill="white"/>
                            <path
                                d="M595.18 643.849C595.669 643.36 596.035 642.75 596.524 642.262C597.012 641.774 597.501 641.286 597.99 640.798C598.356 640.431 597.867 639.943 597.501 640.187C596.768 640.553 596.279 641.042 595.791 641.652C595.302 642.14 594.814 642.75 594.691 643.482C594.691 643.849 595.058 644.093 595.18 643.849Z"
                                fill="white"/>
                            <path
                                d="M594.447 637.014C594.813 636.648 595.18 636.404 595.669 636.038C596.035 635.794 596.279 635.672 596.402 635.183C596.402 635.061 596.279 634.817 596.157 634.817C595.669 634.817 595.546 635.061 595.18 635.428C594.813 635.794 594.325 636.16 593.958 636.526C593.592 636.892 594.081 637.38 594.447 637.014Z"
                                fill="white"/>
                            <path
                                d="M595.547 631.278C595.791 630.912 596.035 630.546 596.524 630.424C596.89 630.302 597.257 630.302 597.501 630.058C597.623 629.936 597.746 629.814 597.501 629.692C596.524 628.837 595.18 630.18 594.814 631.034C594.692 631.4 595.302 631.766 595.547 631.278Z"
                                fill="white"/>
                            <path
                                d="M598.845 623.467C599.211 623.223 599.7 623.101 599.944 622.735C600.066 622.491 599.944 622.247 599.7 622.247C599.211 622.247 598.845 622.613 598.478 622.979C598.112 623.223 598.478 623.59 598.845 623.467Z"
                                fill="white"/>
                            <path
                                d="M598.478 617.487C599.211 616.999 600.066 616.511 600.799 616.023C601.41 615.656 602.265 615.29 602.631 614.68C602.754 614.436 602.631 614.192 602.387 614.192C601.654 614.192 601.043 614.802 600.433 615.168C599.7 615.656 598.967 616.145 598.112 616.633C597.745 617.121 598.112 617.731 598.478 617.487Z"
                                fill="white"/>
                            <path
                                d="M599.822 610.287C600.555 609.921 601.288 609.799 602.021 609.677C602.509 609.677 603.12 609.799 603.609 609.433C603.731 609.311 603.731 609.189 603.609 609.066C603.12 608.578 602.387 608.822 601.776 608.944C600.921 609.066 600.188 609.311 599.456 609.677C599.089 609.799 599.455 610.409 599.822 610.287Z"
                                fill="white"/>
                            <path
                                d="M603.609 605.161C604.219 604.917 605.197 604.672 605.563 604.062C605.685 603.94 605.563 603.574 605.319 603.574C604.586 603.452 603.731 604.184 603.12 604.55C602.754 604.672 603.12 605.283 603.609 605.161Z"
                                fill="white"/>
                            <path
                                d="M597.379 595.763C597.745 596.252 598.234 597.228 598.845 597.472C598.967 597.472 599.211 597.35 599.211 597.228C599.211 596.496 598.356 595.885 597.99 595.397C597.745 595.031 597.135 595.519 597.379 595.763Z"
                                fill="white"/>
                            <path
                                d="M598.723 695.961H569.161C565.741 695.961 563.053 693.276 563.053 689.859V650.195H604.83V689.859C604.83 693.154 602.143 695.961 598.723 695.961Z"
                                fill="#82A7C3"/>
                            <path
                                d="M226.76 695.839C227.005 696.205 227.371 696.449 227.738 696.693C228.837 697.304 229.203 696.693 230.303 697.304C230.791 697.548 232.013 699.134 232.502 699.378C235.678 700.965 239.098 702.429 242.518 703.528C243.984 704.016 245.572 704.382 247.16 704.504C249.115 702.796 245.45 700.111 243.862 698.28C241.541 695.473 239.831 692.178 238.976 688.761C238.731 687.784 238.487 686.808 237.876 685.954C235.922 683.757 232.624 682.414 231.158 685.221C230.425 686.564 226.027 694.497 226.76 695.839Z"
                                fill="#FBAF42"/>
                            <path
                                d="M325.462 696.937C329.126 697.914 335.845 698.158 339.509 697.67C340.365 697.548 341.464 697.181 341.464 696.327C341.586 695.473 340.731 694.985 339.998 694.619C335.356 692.056 328.515 688.394 325.584 683.879C325.462 683.635 325.339 683.391 325.095 683.269C324.973 683.146 324.729 683.146 324.484 683.146C320.82 682.78 318.132 682.292 318.621 685.954C318.865 687.662 318.865 693.764 319.476 695.351C320.087 697.303 319.72 696.693 321.797 697.303C323.018 697.792 324.24 696.693 325.462 696.937Z"
                                fill="#FBAF42"/>
                            <path
                                d="M262.185 600.645C261.941 620.172 261.452 624.566 255.1 635.916C247.282 650.073 237.998 665.573 231.158 684.977C230.303 687.296 236.532 688.273 238.853 687.662C246.183 674.116 259.375 656.541 269.27 641.652C280.142 625.298 283.196 611.751 288.571 595.642C297.488 616.877 307.749 629.936 309.826 636.526C310.803 639.577 317.033 679.363 318.621 685.954C320.942 685.954 324.606 684.977 326.805 684.977C328.149 667.769 325.461 636.648 322.896 627.373C321.43 622.125 309.215 576.115 308.115 570.745C307.871 569.402 307.505 567.938 306.527 566.962C303.84 564.521 299.809 567.328 296.877 569.524C289.67 575.016 271.835 568.182 263.162 569.524C263.162 569.524 260.597 574.162 261.086 584.414C261.208 588.929 262.307 594.299 262.185 600.645Z"
                                fill="#53628A"/>
                            <path
                                d="M348.549 493.247C357.589 491.783 363.574 491.295 371.759 487.267C374.446 485.925 385.929 476.649 386.784 479.456C388.25 484.094 383.363 488.976 379.332 491.661C369.682 498.251 361.62 504.719 349.893 505.94C343.907 506.55 337.922 505.818 332.058 505.085C326.928 504.475 288.205 502.4 304.329 491.295C314.59 484.46 337.067 495.2 348.549 493.247Z"
                                fill="#CCE5EA"/>
                            <path
                                d="M262.307 506.062C262.185 509.845 262.063 522.904 262.307 526.687C263.895 549.021 259.376 568.182 262.796 570.378C272.446 572.453 306.039 572.575 308.238 570.378C309.948 568.67 309.215 552.194 310.925 535.108C312.147 522.415 316.422 513.14 314.59 501.18C313.857 496.298 309.948 489.708 309.093 489.586C297.122 487.389 263.04 488.243 262.307 506.062Z"
                                fill="#E4F5F9"/>
                            <path
                                d="M280.997 492.515C284.906 495.2 289.548 498.495 292.48 501.302C292.48 501.302 293.823 499.472 295.534 496.787C297.366 494.102 297.122 490.074 296.389 489.586C291.869 486.779 297.122 479.456 293.457 476.405C290.403 473.842 283.929 475.673 282.096 478.236C281.241 479.334 286.128 487.999 280.997 492.515Z"
                                fill="#FDC28B"/>
                            <path
                                d="M275.989 470.181C277.21 473.598 278.065 476.527 280.631 479.09C282.219 480.677 286.372 483.972 288.449 484.704C295.534 487.267 299.076 483.24 299.443 482.874C307.138 475.551 304.695 457 301.641 454.682C296.633 450.898 288.082 451.997 282.463 454.682C275.622 457.733 273.668 463.713 275.989 470.181Z"
                                fill="#FDC28B"/>
                            <path
                                d="M279.898 468.106C280.875 467.496 282.096 468.106 283.074 469.205C282.341 466.154 282.829 461.638 285.272 459.563C287.471 457.733 290.403 457.367 293.335 457.367C295.778 457.489 298.221 457.489 300.664 457.611C302.13 457.611 303.718 457.733 304.939 456.878C307.383 455.414 308.848 449.312 305.184 446.139C304.695 445.772 291.38 447.603 285.15 449.922C283.074 450.654 280.997 451.509 279.165 452.729C274.279 456.024 272.08 462.492 273.179 468.228C274.279 473.964 277.577 478.358 282.707 481.287C282.341 476.894 283.196 475.063 283.196 475.063C282.463 475.917 281.852 475.307 281.119 474.697C279.287 472.988 278.31 469.083 279.898 468.106Z"
                                fill="#DB8E28"/>
                            <path
                                d="M298.588 476.039C296.389 476.283 290.77 476.527 289.915 476.527C290.159 478.114 291.869 480.677 294.679 480.677C298.221 480.677 298.71 477.259 298.588 476.039Z"
                                fill="white"/>
                            <path
                                d="M278.065 491.539C277.943 493.735 282.951 501.668 286.983 503.865C288.082 503.865 288.815 501.18 289.426 499.593C290.159 500.448 292.357 502.644 292.724 502.644C293.09 502.644 294.8 499.105 295.533 497.885C296.511 498.617 297.121 500.57 298.343 500.326C299.442 500.082 300.908 490.562 299.564 488.609C298.587 487.267 294.923 486.169 294.556 486.901C294.556 486.901 295.045 488.854 295.289 489.342C295.9 490.806 295.289 494.712 294.312 496.298C293.212 498.007 293.212 499.227 292.479 501.058C291.991 500.326 290.891 497.641 290.403 497.275C290.036 497.03 286.372 494.956 285.394 493.613C284.662 492.515 283.562 489.342 283.196 487.511C283.074 486.413 278.065 489.586 278.065 491.539Z"
                                fill="#CCE5EA"/>
                            <path d="M281.576 510.224L292.226 531.239L415.599 468.836L404.949 447.82L281.576 510.224Z"
                                  fill="#2E85DE"/>
                            <path d="M285.33 517.629L288.42 523.727L411.793 461.323L408.704 455.226L285.33 517.629Z"
                                  fill="#8DBFF4"/>
                            <path
                                d="M404.862 447.725L418.666 447.847L430.637 447.969L423.674 457.611L415.612 468.839L404.862 447.725Z"
                                fill="#FDC28B"/>
                            <path d="M418.666 447.847L430.637 447.969L423.674 457.611L418.666 447.847Z" fill="#53628A"/>
                            <path
                                d="M274.523 520.707L279.531 530.593C281.119 533.644 284.906 534.864 287.96 533.4L292.235 531.203L281.608 510.089L277.333 512.286C274.156 513.873 272.935 517.656 274.523 520.707Z"
                                fill="#53628A"/>
                            <path
                                d="M295.412 515.459C295.656 514.971 296.267 514.239 296.756 514.605C297.366 515.459 297.611 516.435 297.611 517.412C297.611 518.144 297.366 519.12 297.977 519.609C298.221 519.853 298.71 519.853 299.077 519.853C301.764 519.853 304.451 518.876 306.406 517.046C307.383 516.191 308.36 515.093 309.582 515.459C310.315 515.703 310.803 516.313 310.926 516.924C311.048 517.656 311.048 518.388 310.803 518.998C310.071 521.805 308.36 524.368 305.795 525.955C303.596 527.419 300.909 528.03 298.344 528.518C297.611 528.64 296.756 528.762 296.267 528.396C292.847 526.077 294.068 518.51 295.412 515.459Z"
                                fill="#FDC28B"/>
                            <path
                                d="M254.612 549.631C261.208 553.415 275.623 546.946 281.975 542.797C288.327 538.647 292.114 534.864 297.488 529.616C298.466 528.64 295.9 521.561 294.068 522.904C285.761 529.128 275.989 531.935 266.217 535.718C261.575 537.549 283.563 490.929 268.904 495.2C254.734 499.227 246.916 545.238 254.612 549.631Z"
                                fill="#CCE5EA"/>
                            <path
                                d="M380.309 486.657C379.699 485.925 379.699 485.193 380.676 483.728C381.165 482.874 383.485 481.775 384.096 481.043C384.951 480.067 385.806 478.968 386.784 477.992C389.715 475.429 390.937 476.405 391.059 476.528C391.914 478.602 390.815 481.287 389.349 482.996C388.616 483.85 385.929 486.169 384.829 486.413C384.341 486.657 381.653 488 380.309 486.657Z"
                                fill="#FDC28B"/>
                        </g>
                        <rect x="261" y="262.952" width="17.4864" height="71.8887" rx="8.74322"
                              transform="rotate(-56.7022 261 262.952)" fill="#FBC04F"/>
                        <rect x="372.59" y="157.981" width="17.4864" height="71.8887" rx="8.74322"
                              transform="rotate(-20.0028 372.59 157.981)" fill="#FBC04F"/>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="526" height="485" fill="white" transform="translate(195 230)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import ProgressBarSteps from "./ProgressBarStep";
    import Wysiwyg from "../../Wysiwyg";

    export default {
        name: "MaterialStep",
        components: {
            ProgressBarSteps,
            Wysiwyg
        },
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
            }
        },
        methods: {
            async onNext() {
                let isValid = await this.$refs.observer.validate();
                if (isValid) {
                    this.form.skip = false;
                    this.$emit('next');
                }
            },
            onPrev() {
                this.$emit('prev');
            },
            async onSkip() {
                await this.$metrika.reachGoal('skip-material-step');
                this.form.skip = true;
                this.$emit('next');
            }
        },
        async mounted() {
            await this.$metrika.reachGoal('company-step');
        }
    }
</script>

<style lang="scss">
    @import '#sass/v-style';

    // Editor styles
    /deep/ .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable {
        height: 280px;
        border-radius: 0 0 12px 12px;
        border-color: #E0E3F0;
        transition: .2s linear border-color;
    }

    /deep/ .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
        border-radius: 12px 12px 0 0;
        border-color: #E0E3F0;
        transition: .2s linear border-color;
    }

    /deep/ .ck.ck-dropdown__panel.ck-dropdown__panel_sw {
        width: 260px;
    }

    /deep/ .ck.ck-toolbar-dropdown .ck.ck-toolbar .ck.ck-toolbar__items {
        flex-wrap: wrap;
    }

    .wizard-starting__form-group.is-invalid /deep/ .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
    .wizard-starting__form-group.is-invalid /deep/ .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
        border-color: #ef5350;
    }
</style>
