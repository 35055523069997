<template>
    <div class="wizard-starting__page learn">
        <div class="wizard-starting__logo">
            <img src="../../../assets/img/wikiworks-logo.png" alt="Wikiworks">
        </div>
        <div class="wizard-starting__row">
            <div class="wizard-starting__col wizard-starting__col_left">
                <div class="wizard-starting__block">
                    <div>
                        <h3 class="wizard-starting__title">
                            Изучение регламента и тестирование будут проходить следующим образом
                        </h3>
                        <img
                            src="../../../assets/img/wizard-starting/learn.gif"
                            alt="Изучение регламента и тестирование"
                            class="learn__img"
                        >
                        <div class="wizard-starting__btn-group">
                            <button
                                class="wizard-starting__btn wizard-starting__btn_prev"
                                @click="onPrev"
                            >
                                Назад
                            </button>
                            <button
                                class="wizard-starting__btn wizard-starting__btn_next"
                                @click="onNext"
                            >
                                Далее
                            </button>
                        </div>
                    </div>
                    <div>
                        <progress-bar-steps :step="6"></progress-bar-steps>
                    </div>
                </div>
            </div>
            <div class="wizard-starting__col wizard-starting__col_right">
                <svg preserveAspectRatio="xMidYMid slice" width="960" height="937" viewBox="0 0 960 937" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <rect width="960" height="937" fill="#F2F2F2"/>
                    <rect y="606" width="960" height="331" fill="#FBC04F"/>
                    <g clip-path="url(#clip0)">
                        <path
                            d="M383.738 351.126L290.967 445.056L164 319.867L244.198 238.655L255.238 237.735L267.812 236.816L383.738 351.126Z"
                            fill="#E6E7E8"/>
                        <path d="M244.197 238.655L255.238 249.534L267.812 236.816L244.197 238.655Z" fill="#A6A8AB"/>
                        <path d="M212.262 299.136L186.2 325.534L212.618 351.577L238.679 325.178L212.262 299.136Z"
                              fill="#D0D2D3"/>
                        <path d="M257.399 256.141L221.753 292.248L225.028 295.476L260.674 259.369L257.399 256.141Z"
                              fill="#D0D2D3"/>
                        <path d="M264.299 262.795L228.653 298.902L231.928 302.13L267.574 266.023L264.299 262.795Z"
                              fill="#D0D2D3"/>
                        <path d="M271.09 269.558L235.444 305.665L238.719 308.893L274.365 272.786L271.09 269.558Z"
                              fill="#D0D2D3"/>
                        <path d="M277.774 276.214L242.128 312.321L245.403 315.549L281.049 279.442L277.774 276.214Z"
                              fill="#D0D2D3"/>
                        <path d="M248.474 334.819L222.412 361.218L248.83 387.26L274.891 360.862L248.474 334.819Z"
                              fill="#D0D2D3"/>
                        <path d="M293.611 291.824L257.965 327.931L261.24 331.159L296.886 295.052L293.611 291.824Z"
                              fill="#D0D2D3"/>
                        <path d="M300.511 298.478L264.865 334.585L268.14 337.814L303.786 301.707L300.511 298.478Z"
                              fill="#D0D2D3"/>
                        <path d="M307.302 305.242L271.656 341.349L274.931 344.577L310.577 308.47L307.302 305.242Z"
                              fill="#D0D2D3"/>
                        <path d="M313.986 311.897L278.34 348.004L281.615 351.233L317.261 315.126L313.986 311.897Z"
                              fill="#D0D2D3"/>
                        <path d="M284.685 370.502L258.624 396.901L285.042 422.943L311.103 396.545L284.685 370.502Z"
                              fill="#D0D2D3"/>
                        <path d="M329.823 327.507L294.177 363.614L297.452 366.842L333.098 330.735L329.823 327.507Z"
                              fill="#D0D2D3"/>
                        <path d="M336.723 334.161L301.077 370.268L304.352 373.496L339.998 337.389L336.723 334.161Z"
                              fill="#D0D2D3"/>
                        <path d="M343.405 340.817L307.759 376.924L311.034 380.153L346.68 344.046L343.405 340.817Z"
                              fill="#D0D2D3"/>
                        <path d="M350.198 347.581L314.552 383.688L317.827 386.916L353.473 350.809L350.198 347.581Z"
                              fill="#D0D2D3"/>
                        <path
                            d="M385.119 341.319L284.373 426.362L169.214 290.14L256.465 216.589L267.506 216.743L280.08 216.896L385.119 341.319Z"
                            fill="#F9FAFE"/>
                        <path d="M256.465 216.589L266.432 228.388L280.079 216.896L256.465 216.589Z" fill="#A6A8AB"/>
                        <path d="M219.065 273.983L190.722 297.915L214.672 326.238L243.015 302.306L219.065 273.983Z"
                              fill="#D0D2D3"/>
                        <path d="M268.028 235.275L229.261 268.009L232.23 271.52L270.997 238.786L268.028 235.275Z"
                              fill="#D0D2D3"/>
                        <path d="M274.213 242.631L235.445 275.365L238.414 278.876L277.182 246.142L274.213 242.631Z"
                              fill="#D0D2D3"/>
                        <path d="M280.415 249.77L241.647 282.504L244.616 286.016L283.384 253.281L280.415 249.77Z"
                              fill="#D0D2D3"/>
                        <path d="M286.599 257.127L247.832 289.861L250.801 293.372L289.568 260.638L286.599 257.127Z"
                              fill="#D0D2D3"/>
                        <path d="M251.959 312.862L223.615 336.794L247.565 365.117L275.909 341.185L251.959 312.862Z"
                              fill="#D0D2D3"/>
                        <path d="M300.822 274.037L262.055 306.771L265.024 310.282L303.791 277.548L300.822 274.037Z"
                              fill="#D0D2D3"/>
                        <path d="M307.007 281.392L268.239 314.126L271.208 317.638L309.976 284.903L307.007 281.392Z"
                              fill="#D0D2D3"/>
                        <path d="M313.092 288.631L274.324 321.365L277.293 324.876L316.061 292.142L313.092 288.631Z"
                              fill="#D0D2D3"/>
                        <path d="M319.393 295.889L280.626 328.623L283.595 332.134L322.362 299.4L319.393 295.889Z"
                              fill="#D0D2D3"/>
                        <path d="M284.753 351.625L256.409 375.557L280.359 403.88L308.703 379.948L284.753 351.625Z"
                              fill="#D0D2D3"/>
                        <path d="M333.617 312.799L294.85 345.533L297.819 349.044L336.586 316.31L333.617 312.799Z"
                              fill="#D0D2D3"/>
                        <path d="M339.802 320.155L301.034 352.889L304.003 356.4L342.771 323.666L339.802 320.155Z"
                              fill="#D0D2D3"/>
                        <path d="M345.887 327.394L307.119 360.128L310.088 363.64L348.856 330.905L345.887 327.394Z"
                              fill="#D0D2D3"/>
                        <path d="M352.187 334.651L313.42 367.385L316.389 370.896L355.156 338.162L352.187 334.651Z"
                              fill="#D0D2D3"/>
                        <path
                            d="M246.344 325.996L233.924 304.697C230.55 298.874 232.544 291.366 238.371 287.995L317.801 242.026C323.628 238.654 331.142 240.646 334.516 246.469L422.227 397.707C425.601 403.53 423.607 411.039 417.78 414.41L353.53 451.491L327.615 466.508L317.035 472.637C311.208 476.008 303.694 474.016 300.321 468.193L237.297 359.4C233.924 353.577 235.917 346.069 241.744 342.698C247.724 339.174 249.718 331.818 246.344 325.996Z"
                            fill="#FFCE55"/>
                        <g opacity="0.3">
                            <path opacity="0.3"
                                  d="M309.828 466.508L309.675 466.202L244.351 353.424L250.178 350.053C258.305 345.303 261.066 334.883 256.465 326.762L238.831 296.422L239.138 296.269L326.082 245.856L326.236 246.163L417.78 403.99L417.474 404.143L309.828 466.508ZM244.965 353.577L309.981 465.742L417.014 403.837L325.929 246.623L239.598 296.576L256.925 326.302C261.832 334.73 258.919 345.456 250.485 350.359L244.965 353.577Z"
                                  fill="white"/>
                        </g>
                        <g opacity="0.2">
                            <path opacity="0.2"
                                  d="M390.332 397.861C393.858 395.716 395.085 391.272 393.092 387.594L326.542 272.672C324.395 269.147 319.948 267.922 316.268 269.914C312.741 272.059 311.514 276.502 313.508 280.18L380.058 395.103C382.204 398.627 386.805 399.853 390.332 397.861Z"
                                  fill="white"/>
                        </g>
                        <path
                            d="M656.073 592.463H304.461C297.407 592.463 291.733 586.794 291.733 579.745V358.021C291.733 350.972 297.407 345.303 304.461 345.303H656.073C663.126 345.303 668.8 350.972 668.8 358.021V579.745C668.8 586.794 662.973 592.463 656.073 592.463Z"
                            fill="#303637"/>
                        <path
                            d="M646.566 580.358H313.815C307.221 580.358 301.701 575.302 301.701 568.866V368.9C301.701 362.618 307.068 357.408 313.815 357.408H646.566C653.16 357.408 658.68 362.465 658.68 368.9V568.866C658.68 575.302 653.313 580.358 646.566 580.358Z"
                            fill="#55B6FA"/>
                        <path
                            d="M455.655 592.463V667.699H504.724V592.463H455.655ZM480.19 648.852C471.296 648.852 464.242 641.65 464.242 632.916C464.242 624.029 471.449 616.98 480.19 616.98C489.084 616.98 496.137 624.182 496.137 632.916C496.137 641.65 489.084 648.852 480.19 648.852Z"
                            fill="#303637"/>
                        <path d="M555.481 667.699H404.899V682.563H555.481V667.699Z" fill="#303637"/>
                        <path d="M698.395 468.194H695.175V469.726H698.395V468.194Z" fill="#A4AEBD"/>
                        <path
                            d="M727.53 469.726H720.17V468.194H727.53V469.726ZM712.963 469.726H705.603V468.194H712.963V469.726Z"
                            fill="#A4AEBD"/>
                        <path d="M738.878 469.726H734.891V468.193H737.344V465.742H738.878V469.726Z" fill="#A4AEBD"/>
                        <path
                            d="M738.877 459.306H737.344V452.87H738.877V459.306ZM738.877 446.435H737.344V439.999H738.877V446.435ZM738.877 433.563H737.344V427.128H738.877V433.563ZM738.877 420.692H737.344V414.256H738.877V420.692ZM738.877 407.821H737.344V401.385H738.877V407.821ZM738.877 394.949H737.344V388.514H738.877V394.949ZM738.877 382.078H737.344V375.642H738.877V382.078ZM738.877 369.207H737.344V362.771H738.877V369.207ZM738.877 356.335H737.344V349.9H738.877V356.335ZM738.877 343.464H737.344V337.028H738.877V343.464ZM738.877 330.593H737.344V324.157H738.877V330.593ZM738.877 317.721H737.344V311.286H738.877V317.721ZM738.877 304.85H737.344V298.414H738.877V304.85ZM738.877 291.979H737.344V285.543H738.877V291.979ZM738.877 279.107H737.344V272.672H738.877V279.107ZM738.877 266.236H737.344V259.8H738.877V266.236ZM738.877 253.365H737.344V246.929H738.877V253.365ZM738.877 240.493H737.344V234.058H738.877V240.493ZM738.877 227.622H737.344V221.186H738.877V227.622ZM738.877 214.751H737.344V208.315H738.877V214.751Z"
                            fill="#A4AEBD"/>
                        <path d="M738.878 201.879H737.344V199.428H734.891V197.896H738.878V201.879Z" fill="#A4AEBD"/>
                        <path
                            d="M728.45 199.428H722.009V197.896H728.45V199.428ZM715.569 199.428H709.129V197.896H715.569V199.428ZM702.688 199.428H696.248V197.896H702.688V199.428ZM689.808 199.428H683.367V197.896H689.808V199.428ZM676.927 199.428H670.486V197.896H676.927V199.428ZM664.046 199.428H657.606V197.896H664.046V199.428ZM651.166 199.428H644.725V197.896H651.166V199.428ZM638.285 199.428H631.691V197.896H638.131V199.428H638.285ZM625.251 199.428H618.81V197.896H625.251V199.428ZM612.37 199.428H605.93V197.896H612.37V199.428ZM599.489 199.428H593.049V197.896H599.489V199.428ZM586.609 199.428H580.168V197.896H586.609V199.428ZM573.728 199.428H567.288V197.896H573.728V199.428ZM560.847 199.428H554.407V197.896H560.847V199.428ZM547.967 199.428H541.526V197.896H547.967V199.428ZM535.086 199.428H528.646V197.896H535.086V199.428ZM522.205 199.428H515.765V197.896H522.205V199.428ZM509.325 199.428H502.884V197.896H509.325V199.428ZM496.444 199.428H490.004V197.896H496.444V199.428ZM483.563 199.428H477.123V197.896H483.563V199.428ZM470.683 199.428H464.242V197.896H470.683V199.428ZM457.649 199.428H451.208V197.896H457.649V199.428ZM444.768 199.428H438.328V197.896H444.768V199.428ZM431.887 199.428H425.447V197.896H431.887V199.428ZM419.007 199.428H412.566V197.896H419.007V199.428ZM406.126 199.428H399.686V197.896H406.126V199.428Z"
                            fill="#A4AEBD"/>
                        <path d="M393.246 197.896H390.025V199.428H393.246V197.896Z" fill="#A4AEBD"/>
                        <path
                            d="M693.947 476.927C698.182 476.927 701.615 473.497 701.615 469.266C701.615 465.035 698.182 461.604 693.947 461.604C689.713 461.604 686.28 465.035 686.28 469.266C686.28 473.497 689.713 476.927 693.947 476.927Z"
                            fill="#A4AEBD"/>
                        <path
                            d="M377.667 206.323C381.901 206.323 385.334 202.893 385.334 198.662C385.334 194.43 381.901 191 377.667 191C373.433 191 370 194.43 370 198.662C370 202.893 373.433 206.323 377.667 206.323Z"
                            fill="#FBC04F"/>
                        <path
                            d="M409.193 422.838C415.327 420.386 423.147 418.7 428.054 423.144C430.661 425.443 431.735 428.967 432.655 432.338C434.341 439.08 435.108 445.975 435.875 452.718C437.102 462.831 438.022 472.944 436.795 483.057C435.415 493.17 431.581 503.13 424.221 510.179C422.534 511.864 420.387 513.397 418.087 513.856C416.4 514.163 414.56 514.01 412.874 513.703C406.893 512.937 401.066 512.018 395.086 511.251C393.093 510.945 391.099 510.639 389.106 509.872C386.346 508.8 384.199 506.655 382.205 504.356C369.938 490.259 366.411 469.879 371.931 452.258C376.992 436.628 394.933 428.507 409.193 422.838Z"
                            fill="#FFA97C"/>
                        <g opacity="0.3">
                            <path opacity="0.3" d="M397.539 445.822L390.025 449.04L397.539 447.967V444.903"
                                  fill="#D66634"/>
                        </g>
                        <path d="M574.342 292.285H397.539V522.59H574.342V292.285Z" fill="#F9FAFE"/>
                        <path d="M574.342 292.285H397.539V306.995H574.342V292.285Z" fill="#303637"/>
                        <path
                            d="M562.381 301.326C563.906 301.326 565.141 300.091 565.141 298.568C565.141 297.044 563.906 295.81 562.381 295.81C560.857 295.81 559.621 297.044 559.621 298.568C559.621 300.091 560.857 301.326 562.381 301.326Z"
                            fill="#F06359"/>
                        <path
                            d="M552.107 301.326C553.631 301.326 554.867 300.091 554.867 298.568C554.867 297.044 553.631 295.81 552.107 295.81C550.582 295.81 549.347 297.044 549.347 298.568C549.347 300.091 550.582 301.326 552.107 301.326Z"
                            fill="#E6E7E8"/>
                        <path
                            d="M541.68 301.326C543.204 301.326 544.44 300.091 544.44 298.568C544.44 297.044 543.204 295.81 541.68 295.81C540.156 295.81 538.92 297.044 538.92 298.568C538.92 300.091 540.156 301.326 541.68 301.326Z"
                            fill="#E6E7E8"/>
                        <path
                            d="M447.988 715.661H248.184C246.037 715.661 244.351 713.975 244.351 711.83C244.351 709.685 246.037 707.999 248.184 707.999H447.988C450.135 707.999 451.822 709.685 451.822 711.83C451.822 713.975 450.135 715.661 447.988 715.661Z"
                            fill="#F1F1F2"/>
                        <path
                            d="M572.041 261.639H448.908C446.761 261.639 445.074 259.954 445.074 257.808C445.074 255.663 446.761 253.978 448.908 253.978H572.041C574.188 253.978 575.875 255.663 575.875 257.808C575.875 259.954 574.188 261.639 572.041 261.639Z"
                            fill="#F1F1F2"/>
                        <path
                            d="M741.638 746H618.504C616.358 746 614.671 744.314 614.671 742.169C614.671 740.024 616.358 738.338 618.504 738.338H741.638C743.784 738.338 745.471 740.024 745.471 742.169C745.471 744.314 743.784 746 741.638 746Z"
                            fill="#F1F1F2"/>
                        <path
                            d="M285.753 669.691C281.459 669.691 277.933 666.167 277.933 661.877C277.933 657.586 281.459 654.062 285.753 654.062C290.047 654.062 293.573 657.586 293.573 661.877C293.573 666.167 290.047 669.691 285.753 669.691ZM285.753 657.893C283.606 657.893 281.766 659.731 281.766 661.877C281.766 664.022 283.606 665.861 285.753 665.861C287.9 665.861 289.74 664.022 289.74 661.877C289.74 659.731 287.9 657.893 285.753 657.893Z"
                            fill="#F1F1F2"/>
                        <path
                            d="M578.482 239.88C573.268 239.88 568.975 235.59 568.975 230.38C568.975 225.17 573.268 220.88 578.482 220.88C583.695 220.88 587.989 225.17 587.989 230.38C587.989 235.59 583.695 239.88 578.482 239.88ZM578.482 225.477C575.722 225.477 573.575 227.622 573.575 230.38C573.575 233.138 575.722 235.284 578.482 235.284C581.242 235.284 583.389 233.138 583.389 230.38C583.389 227.622 581.089 225.477 578.482 225.477Z"
                            fill="#F1F1F2"/>
                        <path
                            d="M734.43 711.063C729.216 711.063 724.923 706.773 724.923 701.563C724.923 696.353 729.216 692.063 734.43 692.063C739.644 692.063 743.937 696.353 743.937 701.563C743.937 706.773 739.644 711.063 734.43 711.063ZM734.43 696.66C731.67 696.66 729.523 698.805 729.523 701.563C729.523 704.321 731.67 706.467 734.43 706.467C737.19 706.467 739.337 704.321 739.337 701.563C739.337 698.805 737.037 696.66 734.43 696.66Z"
                            fill="#F1F1F2"/>
                        <path
                            d="M439.861 382.384C456.122 382.384 469.303 369.213 469.303 352.964C469.303 336.716 456.122 323.544 439.861 323.544C423.601 323.544 410.42 336.716 410.42 352.964C410.42 369.213 423.601 382.384 439.861 382.384Z"
                            fill="#929497"/>
                        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="410" y="323" width="60"
                              height="60">
                            <path
                                d="M439.861 382.384C456.122 382.384 469.303 369.213 469.303 352.964C469.303 336.716 456.122 323.544 439.861 323.544C423.601 323.544 410.42 336.716 410.42 352.964C410.42 369.213 423.601 382.384 439.861 382.384Z"
                                fill="#929497"/>
                        </mask>
                        <g mask="url(#mask0)">
                            <path
                                d="M443.848 367.368L446.455 367.981C451.362 369.053 454.736 373.344 454.736 378.4V382.231C454.736 382.844 454.276 383.457 453.509 383.457H439.708H425.907C425.294 383.457 424.681 382.997 424.681 382.231V378.4C424.681 373.344 428.208 369.053 432.961 367.981L435.261 367.521C435.261 367.521 435.261 367.521 435.415 367.521L438.021 367.214H438.175H441.548H441.702L443.848 367.368Z"
                                fill="#F7D9BC"/>
                            <path
                                d="M448.448 368.44L443.848 367.368L442.621 367.214V370.126V369.973V370.126C442.468 371.505 441.394 372.577 440.014 372.577C438.481 372.577 437.254 371.352 437.254 369.819V367.214L435.721 367.368L431.58 368.287C430.507 368.593 429.587 368.9 428.667 369.513V383.15H440.014H451.515V369.513C450.595 369.206 449.521 368.747 448.448 368.44Z"
                                fill="#00ADEE"/>
                            <path d="M428.668 377.94H425.141C425.141 377.94 424.221 372.731 428.668 369.666V377.94Z"
                                  fill="#00ADEE"/>
                            <path d="M451.516 377.94H455.042C455.042 377.94 455.963 372.731 451.516 369.666V377.94Z"
                                  fill="#00ADEE"/>
                            <path
                                d="M442.775 367.214V370.126V369.972C442.775 371.505 441.548 372.73 440.015 372.73C438.482 372.73 437.255 371.505 437.255 369.972V365.682L437.868 365.069H438.328L440.935 364.456L441.702 364.609L442.622 364.916"
                                fill="#F7D9BC"/>
                            <path
                                d="M441.089 364.456L438.483 365.069H438.023L437.409 365.682V367.674C438.176 368.134 439.096 368.44 440.169 368.44C441.089 368.44 442.009 368.134 442.776 367.674V367.061V364.763L441.856 364.456H441.089Z"
                                fill="#F6A49B"/>
                            <path
                                d="M440.015 366.601C434.035 366.601 429.281 361.851 429.281 355.875V343.923C429.281 337.947 434.035 333.197 440.015 333.197C445.995 333.197 450.749 337.947 450.749 343.923V355.875C450.749 361.698 445.995 366.601 440.015 366.601Z"
                                fill="#F7D9BC"/>
                            <path
                                d="M450.749 348.827C451.822 348.827 452.896 349.746 452.896 350.972V352.198C452.896 353.27 451.976 354.343 450.749 354.343"
                                fill="#F6A49B"/>
                            <path
                                d="M429.281 348.827C428.207 348.827 427.134 349.746 427.134 350.972V352.198C427.134 353.27 428.054 354.343 429.281 354.343"
                                fill="#F6A49B"/>
                            <path d="M452.896 377.94H451.516V383.303H452.896V377.94Z" fill="#F6A49B"/>
                            <path d="M428.668 377.94H427.288V383.303H428.668V377.94Z" fill="#F6A49B"/>
                            <g opacity="0.2">
                                <path opacity="0.2"
                                      d="M440.015 366.602C445.995 366.602 450.749 361.851 450.749 355.875V348.214C450.749 348.214 450.136 358.787 441.855 362.005C440.629 362.464 439.402 362.464 438.175 362.005C429.895 358.787 429.281 348.214 429.281 348.214V355.875C429.281 361.698 434.035 366.602 440.015 366.602Z"
                                      fill="#F7A584"/>
                            </g>
                            <path
                                d="M449.829 335.342C448.295 331.818 444.615 328.754 436.488 330.133C436.335 330.286 434.341 330.439 433.114 332.584C430.661 332.891 427.594 335.342 429.128 347.754C429.128 347.754 430.968 333.81 435.568 338.867C437.101 340.552 441.088 343.77 450.595 343.923C450.749 345.915 450.595 347.754 450.595 347.754C453.662 338.254 451.362 335.955 449.829 335.342Z"
                                fill="#27282A"/>
                        </g>
                        <path d="M557.014 333.811H482.797V342.851H557.014V333.811Z" fill="#D0D2D3"/>
                        <path d="M557.014 352.964H482.797V362.005H557.014V352.964Z" fill="#D0D2D3"/>
                        <path d="M557.014 372.271H482.797V381.312H557.014V372.271Z" fill="#D0D2D3"/>
                        <path d="M557.167 401.232H444.768V410.273H557.167V401.232Z" fill="#D0D2D3"/>
                        <path d="M557.167 430.192H444.768V439.233H557.167V430.192Z" fill="#D0D2D3"/>
                        <path d="M557.167 459.153H444.768V468.193H557.167V459.153Z" fill="#D0D2D3"/>
                        <path d="M557.167 488.113H444.768V497.154H557.167V488.113Z" fill="#D0D2D3"/>
                        <path
                            d="M429.587 410.886H415.479V398.167H429.434V410.886H429.587ZM417.013 409.353H427.9V399.7H417.013V409.353Z"
                            fill="#58595B"/>
                        <path
                            d="M429.587 441.072H415.479V428.354H429.434V441.072H429.587ZM417.013 439.54H427.9V429.886H417.013V439.54Z"
                            fill="#58595B"/>
                        <path
                            d="M429.587 471.105H415.479V458.387H429.434V471.105H429.587ZM417.013 469.572H427.9V459.919H417.013V469.572Z"
                            fill="#58595B"/>
                        <path
                            d="M429.587 501.291H415.479V488.573H429.434V501.291H429.587ZM417.013 499.759H427.9V490.106H417.013V499.759Z"
                            fill="#58595B"/>
                        <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="301" y="357" width="358"
                              height="224">
                            <path
                                d="M646.808 580.637H314.057C307.464 580.637 301.943 575.58 301.943 569.144V369.179C301.943 362.896 307.31 357.687 314.057 357.687H646.808C653.402 357.687 658.922 362.743 658.922 369.179V569.144C658.922 575.58 653.555 580.637 646.808 580.637Z"
                                fill="#55B6FA"/>
                        </mask>
                        <g mask="url(#mask1)">
                            <path
                                d="M398.152 500.219C395.391 498.227 394.625 494.856 394.471 491.332C392.478 500.372 386.651 500.066 385.271 500.679L385.118 500.832L385.271 500.679C393.858 494.703 394.625 484.283 394.778 482.751C394.931 471.718 391.558 460.686 385.424 451.645C381.437 445.669 375.457 438.927 380.517 431.572C381.897 429.733 383.891 428.507 385.731 427.128C390.024 424.063 393.398 419.62 395.085 414.716C395.851 412.265 397.692 399.087 392.785 400.006C392.018 400.16 391.251 400.619 390.638 401.079C385.271 404.91 379.751 408.434 374.077 411.499C369.017 414.257 365.796 415.176 363.803 420.692C357.516 439.08 350.769 456.702 354.449 476.468C356.749 488.88 349.696 502.058 343.102 512.937C342.029 514.776 340.802 516.615 340.342 518.607C349.849 529.946 359.97 541.285 371.93 549.712C376.377 543.583 380.824 537.301 386.651 532.397C391.711 527.954 392.171 525.502 398.152 522.284V511.405V500.219Z"
                                fill="#FFBE90"/>
                            <path
                                d="M379.138 417.015C379.291 417.168 379.291 417.321 379.445 417.321C379.598 417.321 379.905 417.321 380.058 417.168C382.051 416.096 384.045 415.023 385.885 413.797C386.652 413.184 387.572 412.571 388.185 411.805C389.105 410.732 389.565 409.353 389.719 407.974C390.025 405.829 390.025 403.684 389.565 401.692C385.425 404.603 381.285 407.361 376.838 409.813C376.991 412.571 378.065 415.329 379.138 417.015Z"
                                fill="#F4CDB3"/>
                            <path
                                d="M375.611 418.854C374.691 418.088 374.384 414.87 374.231 413.797C374.078 413.184 374.078 412.418 374.078 411.805L373.924 411.958C373.617 412.112 373.311 412.265 373.004 412.418C372.697 414.87 373.771 417.321 375.611 418.854Z"
                                fill="#FFA97C"/>
                            <path
                                d="M372.851 418.854C371.931 418.087 371.624 414.87 371.471 413.797C371.471 413.644 371.471 413.337 371.471 413.184C371.011 413.337 370.704 413.644 370.244 413.797C370.397 415.789 371.318 417.628 372.851 418.854Z"
                                fill="#FFA97C"/>
                            <g opacity="0.4">
                                <g opacity="0.4">
                                    <path opacity="0.4"
                                          d="M364.416 483.057C364.11 471.412 357.669 460.992 355.523 449.653C355.369 449.04 355.369 448.58 355.216 447.967C353.069 457.314 352.302 466.661 354.143 476.775C356.443 489.186 349.389 502.364 342.795 513.243C341.722 515.082 340.495 516.921 340.035 518.913C341.569 520.752 343.102 522.59 344.635 524.429C346.322 522.284 348.009 519.986 349.542 517.84C356.903 507.267 364.57 495.928 364.416 483.057Z"
                                          fill="#FFA97C"/>
                                </g>
                            </g>
                            <path
                                d="M373.004 432.951C375.611 432.645 378.371 432.185 380.824 431.419C381.131 430.959 381.591 430.653 381.898 430.193C381.285 430.346 380.824 430.499 380.364 430.806C377.758 432.032 374.844 432.798 371.931 433.104C372.391 432.951 372.697 432.951 373.004 432.951Z"
                                fill="#FFA97C"/>
                            <path
                                d="M373.31 547.873C374.23 546.648 374.997 545.575 375.917 544.349C375.15 543.43 374.23 542.51 373.464 541.591C366.563 535.155 359.203 528.72 353.683 521.058C358.743 527.953 363.803 534.849 368.863 541.744C370.244 543.889 371.777 545.881 373.31 547.873Z"
                                fill="#FFA97C"/>
                            <path
                                d="M336.969 505.275C336.969 505.275 350.616 534.083 375.918 552.93C376.378 554.003 371.471 561.204 371.471 561.204C371.471 561.204 339.269 541.284 328.075 512.171C329.149 509.106 336.969 505.275 336.969 505.275Z"
                                fill="#F9FAFE"/>
                            <path
                                d="M325.928 505.275C325.928 505.275 330.988 536.075 383.584 563.503C381.438 561.971 328.075 635.215 328.075 635.215L246.19 570.245L325.928 505.275Z"
                                fill="#4D4D4D"/>
                            <g opacity="0.1">
                                <g opacity="0.1">
                                    <path opacity="0.1"
                                          d="M358.59 547.873H354.297L278.393 595.834L328.075 635.214C328.075 635.214 381.285 562.123 383.585 563.503C373.618 558.293 365.337 553.083 358.59 547.873Z"
                                          fill="#303637"/>
                                </g>
                            </g>
                            <path
                                d="M368.403 566.874C368.403 570.092 365.796 572.697 362.576 572.697C359.356 572.697 356.749 570.092 356.749 566.874C356.749 563.656 359.356 561.051 362.576 561.051C365.796 561.051 368.403 563.656 368.403 566.874Z"
                                fill="#FFCE55"/>
                            <path
                                d="M572.653 426.668C574.187 428.66 575.107 430.805 576.027 433.104C577.867 437.394 579.554 441.685 581.241 446.128C582.927 450.879 584.614 455.629 585.074 460.532C585.381 463.75 585.381 466.968 585.381 470.186C585.074 484.436 584.767 498.533 584.614 512.784C582.467 510.332 581.241 507.114 580.167 504.05C574.34 488.727 569.587 472.944 566.06 456.855C564.833 451.645 563.76 446.282 562.073 441.378C560.846 438.007 559.313 434.789 557.779 431.418C555.326 426.362 553.026 421.305 550.572 416.402C554.099 415.176 557.933 417.934 561.306 419.16C565.293 420.845 569.587 422.837 572.653 426.668Z"
                                fill="#FBA478"/>
                            <path
                                d="M511.163 430.039C512.083 430.805 513.31 431.418 513.924 431.572C516.224 432.031 518.37 430.039 520.517 429.426C521.897 429.12 523.277 428.967 524.657 428.967C527.878 428.813 531.251 428.66 534.471 428.507C534.931 428.507 535.391 428.507 535.698 428.66C536.158 428.813 536.465 429.273 536.618 429.733C537.845 431.572 539.378 433.257 541.065 434.789C541.678 435.249 542.292 435.862 542.445 436.475C542.752 437.701 542.138 438.773 541.525 439.846C540.145 442.604 539.532 445.822 539.992 448.887C539.992 449.346 540.145 449.959 539.992 450.419C539.838 451.032 539.225 451.492 538.765 451.798C535.391 454.556 533.398 458.847 533.551 463.137C538.151 463.443 542.752 462.524 546.892 460.839C549.192 459.919 551.492 458.54 553.179 456.548C555.019 454.25 555.939 451.492 556.092 448.58C556.399 445.669 556.092 442.757 555.786 439.999C555.479 437.394 555.172 434.636 553.946 432.184C552.719 429.886 550.879 427.894 548.579 426.515C545.665 424.523 542.138 423.144 538.612 422.531C532.478 421.152 525.577 420.386 519.751 422.991C516.684 424.37 513.464 425.596 510.397 426.975C510.09 427.128 509.783 427.281 509.63 427.434C509.477 428.201 510.243 429.273 511.163 430.039Z"
                                fill="#E67944"/>
                            <path
                                d="M544.285 450.112C544.285 448.58 543.979 447.048 544.439 445.516C544.899 443.677 546.432 442.451 547.505 440.919C547.659 440.612 547.965 440.306 547.965 439.999C547.965 439.693 547.812 439.386 547.659 439.08C544.592 433.41 544.592 427.741 537.538 425.596C530.331 423.45 521.591 424.829 514.077 424.983C511.624 424.983 508.71 424.676 508.25 421.612C508.25 421.458 508.25 421.152 508.25 420.999C508.403 420.845 508.557 420.692 508.71 420.539C509.937 419.773 511.47 419.62 512.85 419.16C516.684 418.087 520.057 416.555 524.044 415.789C530.178 414.563 536.158 413.49 542.292 412.265C543.212 412.111 543.979 411.958 544.899 412.111C545.819 412.265 546.585 412.877 547.199 413.49C550.572 416.249 553.792 419.007 557.166 421.612C559.466 423.604 562.073 425.596 563.146 428.507C564.066 430.959 563.913 433.564 563.76 436.168C563.146 445.975 563.146 455.782 563.606 465.435C563.76 468.194 563.913 471.718 561.92 474.016C558.239 478.307 547.505 474.629 543.365 472.331C537.845 469.113 536.005 463.75 539.992 458.54C541.832 456.088 544.132 453.79 544.439 450.879C544.285 450.879 544.285 450.419 544.285 450.112Z"
                                fill="#FBA478"/>
                            <path
                                d="M586.301 492.557C585.227 481.218 584.614 470.492 582.467 459.306C582.467 459.153 582.774 461.911 582.774 461.758C583.541 458.081 582.314 454.25 580.627 451.032C578.94 447.661 576.64 444.75 574.8 441.532C570.507 433.87 568.82 424.83 564.373 417.321C563.3 415.483 561.919 413.644 560.079 412.724C558.546 411.958 556.859 411.652 555.172 411.499C549.499 410.732 543.979 410.119 538.305 409.353C534.625 408.894 530.945 408.434 527.264 408.587C521.437 408.894 516.224 410.426 510.55 409.047C509.323 408.74 508.25 409.966 508.25 411.192C508.25 412.418 508.863 413.491 509.63 414.41C512.39 417.781 516.377 420.079 520.671 420.692C523.737 421.152 526.958 420.846 530.024 421.305C532.785 421.765 535.391 422.838 537.998 424.063C542.598 426.055 547.045 428.201 551.646 430.499C553.179 431.265 554.712 432.185 555.019 433.717C555.172 435.096 554.252 436.475 553.332 437.701C549.346 443.37 547.965 450.726 549.806 457.468C550.726 460.992 546.432 462.218 543.519 462.677C540.298 463.29 538.305 461.145 536.618 458.387C533.551 453.484 532.325 447.508 530.638 441.991C530.638 441.685 530.331 441.225 530.178 440.919C529.104 438.007 527.878 435.249 526.191 432.644C525.271 431.265 524.351 429.733 523.277 428.507C521.131 425.902 517.45 424.83 514.23 426.055C512.237 426.822 510.703 428.354 509.783 430.346C507.79 434.483 509.017 438.314 509.63 442.604C510.09 445.056 510.55 447.661 510.857 450.113C511.777 455.322 512.85 460.532 515.15 465.282C518.831 472.791 520.824 480.452 522.971 488.267C525.271 496.541 530.638 504.663 535.238 511.864C544.899 526.881 556.553 540.978 564.066 557.374C568.053 566.261 570.813 575.608 573.113 585.108C574.033 589.092 575.414 608.859 579.247 610.085C580.934 610.545 585.227 607.02 586.914 606.254C591.514 604.569 596.728 603.803 601.482 602.73C604.548 602.117 628.623 595.528 629.85 597.52C609.915 566.721 589.828 529.333 586.301 492.557Z"
                                fill="#FFBE90"/>
                            <path
                                d="M510.09 435.556C510.09 435.556 509.323 424.37 521.437 429.886C521.897 430.039 524.35 438.314 524.35 440.306C524.044 439.693 519.444 444.29 513.157 444.903C512.85 445.056 510.09 435.556 510.09 435.556Z"
                                fill="#F4CDB3"/>
                            <path
                                d="M512.85 411.499C515.457 412.265 518.217 412.571 520.977 412.112C520.823 411.192 520.517 410.12 520.363 409.2C517.45 409.507 514.69 409.66 511.776 409.2C511.93 410.12 512.083 411.039 512.39 411.345C512.39 411.345 512.696 411.499 512.85 411.499Z"
                                fill="#F4CDB3"/>
                            <path
                                d="M523.584 413.184C523.431 411.652 523.431 410.273 523.431 408.74C523.277 408.74 522.971 408.74 522.817 408.893C522.971 410.426 523.277 411.805 523.584 413.184Z"
                                fill="#FFAC7A"/>
                            <path
                                d="M525.884 411.652C525.731 410.579 525.884 409.506 526.037 408.587C525.884 408.587 525.731 408.587 525.424 408.587C525.424 408.74 525.424 408.893 525.424 408.893C525.577 409.813 525.577 410.732 525.884 411.652Z"
                                fill="#FFAC7A"/>
                            <path
                                d="M528.645 420.999C529.258 420.539 529.718 420.233 530.332 419.773C531.252 419.16 532.172 418.394 532.938 417.475C531.712 419.007 528.951 419.926 527.111 420.846C527.571 420.846 528.185 420.846 528.645 420.999Z"
                                fill="#FFAC7A"/>
                            <path
                                d="M544.592 426.821C545.972 426.362 547.352 425.902 548.579 425.289C546.892 425.902 545.205 426.362 543.519 426.362C543.979 426.515 544.285 426.668 544.592 426.821Z"
                                fill="#FFAC7A"/>
                            <path
                                d="M557.626 418.087C557.319 415.942 557.472 413.797 557.779 411.652C557.472 411.499 557.012 411.499 556.706 411.499C556.246 413.95 557.319 415.789 557.626 418.087Z"
                                fill="#FFAC7A"/>
                            <path
                                d="M538.151 460.226C539.071 464.669 539.225 469.266 539.071 473.71C539.531 469.573 539.685 465.435 539.378 461.298C538.918 461.145 538.611 460.685 538.151 460.226Z"
                                fill="#FBA478"/>
                            <path
                                d="M564.832 434.943C561.306 434.33 557.779 433.411 554.405 432.032C554.712 432.491 555.019 432.951 555.172 433.564C555.172 433.871 555.172 434.177 555.172 434.637C558.239 435.403 561.612 435.556 564.832 434.943Z"
                                fill="#FFAC7A"/>
                            <path
                                d="M526.805 442.298C523.124 444.75 518.831 446.741 514.384 447.354C516.224 446.435 517.451 445.516 519.444 444.903C521.744 444.29 524.811 443.677 526.805 442.298Z"
                                fill="#FFAC7A"/>
                            <path
                                d="M515.304 449.346C517.297 449.806 519.597 449.806 521.591 449.346C523.584 448.733 525.578 447.661 526.804 445.975C525.271 446.894 523.584 447.201 521.897 447.967C519.904 448.886 517.604 449.04 515.304 449.346Z"
                                fill="#FFAC7A"/>
                            <path
                                d="M546.126 534.542V542.051C546.126 542.051 591.208 531.478 597.955 521.211C597.189 521.671 593.508 515.389 593.508 515.389C593.508 515.389 589.062 525.962 546.126 534.542Z"
                                fill="#F9FAFE"/>
                            <path
                                d="M606.542 513.55C606.542 513.55 585.841 535.768 536.311 544.043C536.158 544.656 559.619 628.319 559.619 628.319C559.619 628.319 650.244 595.681 649.784 594.762C649.171 593.842 606.542 513.55 606.542 513.55Z"
                                fill="#4D4D4D"/>
                            <g opacity="0.1">
                                <g opacity="0.1">
                                    <path opacity="0.1"
                                          d="M606.235 611.311C597.188 587.1 576.947 532.397 576.333 533.317C571.12 536.228 559.159 539.293 549.652 541.438C545.512 542.51 541.065 543.43 536.311 544.196C536.158 544.809 559.619 628.473 559.619 628.473C559.619 628.473 583.08 619.738 606.235 611.311Z"
                                          fill="#303637"/>
                                </g>
                            </g>
                            <path
                                d="M491.689 407.361C491.689 407.361 543.826 429.273 592.741 465.742C593.048 466.508 594.582 470.799 587.374 472.791C586.914 473.097 507.177 423.604 491.689 407.361Z"
                                fill="#303637"/>
                            <path
                                d="M491.689 407.361C491.689 407.361 496.136 411.652 500.277 414.87C500.277 414.87 503.343 415.176 502.73 412.418C502.577 412.418 491.689 407.361 491.689 407.361Z"
                                fill="#999999"/>
                            <path
                                d="M491.689 407.361L495.37 410.732C495.37 410.732 496.903 410.732 496.75 409.507C496.596 409.66 491.689 407.361 491.689 407.361Z"
                                fill="#131313"/>
                            <path
                                d="M566.52 447.814C565.754 450.419 564.067 453.33 560.387 456.242C560.693 456.395 560.847 456.548 561.153 456.701C564.68 453.79 566.52 450.879 567.287 448.274C567.134 448.12 566.827 447.967 566.52 447.814Z"
                                fill="#131313"/>
                            <g opacity="0.2">
                                <g opacity="0.2">
                                    <path opacity="0.2"
                                          d="M506.717 415.636C506.717 415.636 548.732 436.935 563.3 448.427C563.3 448.887 561.92 450.419 561.92 450.419L506.717 415.636Z"
                                          fill="#F1F1F1"/>
                                </g>
                            </g>
                            <g opacity="0.2">
                                <g opacity="0.2">
                                    <path opacity="0.2"
                                          d="M567.44 453.637L589.828 469.42C589.828 469.42 592.128 468.654 591.362 466.508C591.515 466.355 568.054 451.645 568.054 451.645L567.44 453.637Z"
                                          fill="#F1F1F1"/>
                                </g>
                            </g>
                            <path
                                d="M561.767 555.995C561.767 559.212 559.16 561.817 555.94 561.817C552.72 561.817 550.113 559.212 550.113 555.995C550.113 552.777 552.72 550.172 555.94 550.172C559.16 550.172 561.767 552.777 561.767 555.995Z"
                                fill="#FFCE55"/>
                            <path
                                d="M606.543 513.55C606.543 513.55 598.722 514.009 594.736 517.227C594.582 516.921 597.956 521.211 597.956 521.211L606.543 513.55Z"
                                fill="#303637"/>
                            <path
                                d="M536.312 543.89C536.312 543.89 538.306 538.526 546.28 537.147C546.28 536.688 546.28 541.898 546.28 541.898L536.312 543.89Z"
                                fill="#303637"/>
                        </g>
                        <path
                            d="M325.928 505.276C325.928 505.276 329.608 504.969 331.601 508.647C331.601 508.647 328.841 510.486 328.228 512.171C328.075 512.018 325.928 507.115 325.928 505.276Z"
                            fill="#303637"/>
                        <path
                            d="M375.457 554.309C375.457 554.309 383.738 559.519 383.431 563.503C383.431 563.503 375.151 559.059 373.311 557.833C373.771 557.527 375.457 554.309 375.457 554.309Z"
                            fill="#303637"/>
                        <path
                            d="M422.38 408.161L412.514 397.55L415.551 394.548L422.711 402.241L438.166 388.646L441.04 391.837L422.38 408.161Z"
                            fill="#34BF6E"/>
                        <path
                            d="M422.393 438.103L412.515 427.348L415.564 424.489L422.711 432.04L438.165 418.445L441.039 421.636L422.393 438.103Z"
                            fill="#34BF6E"/>
                        <path
                            d="M422.38 467.759L412.515 457.146L415.564 454.287L422.711 461.838L438.165 448.243L441.039 451.434L422.38 467.759Z"
                            fill="#34BF6E"/>
                        <path
                            d="M422.393 498.258L412.528 487.645L415.564 484.644L422.724 492.337L438.165 478.599L441.052 481.933L422.393 498.258Z"
                            fill="#34BF6E"/>
                        <path
                            d="M765.161 554.84H656.442C640.187 554.84 627 541.663 627 525.42C627 509.178 640.187 496 656.442 496H765.161C781.415 496 794.602 509.178 794.602 525.42C794.602 541.663 781.415 554.84 765.161 554.84Z"
                            fill="#FFCE55"/>
                        <path
                            d="M666.067 526.581L672.201 518.153H674.501V531.178H671.434V523.057L665.3 531.178H663V518.153H666.067V526.581Z"
                            fill="#262626"/>
                        <path
                            d="M678.181 519.992C678.794 519.379 679.407 518.919 680.174 518.613C680.941 518.307 681.861 518.153 682.781 518.153C684.008 518.153 685.081 518.46 685.848 519.073C686.615 519.686 686.921 520.452 686.921 521.524C686.921 522.137 686.768 522.75 686.461 523.21C686.154 523.67 685.694 524.129 684.928 524.436C685.694 524.589 686.308 525.049 686.768 525.508C687.228 526.121 687.381 526.734 687.381 527.5C687.381 528.113 687.228 528.726 687.075 529.186C686.768 529.646 686.461 530.105 686.001 530.412C685.541 530.718 685.081 531.025 684.468 531.178C683.854 531.331 683.241 531.484 682.474 531.484C681.554 531.484 680.634 531.331 679.867 531.025C679.101 530.718 678.334 530.259 677.721 529.646L679.254 527.347C679.714 527.807 680.174 528.113 680.634 528.267C681.094 528.573 681.708 528.573 682.321 528.573C682.934 528.573 683.394 528.42 683.701 528.113C684.008 527.807 684.314 527.347 684.314 526.887C684.314 526.428 684.161 525.968 683.854 525.662C683.548 525.355 682.934 525.202 682.168 525.202H680.634V523.057H682.014C682.781 523.057 683.241 522.903 683.548 522.75C683.854 522.444 684.008 522.137 684.008 521.678C684.008 521.218 683.854 520.911 683.548 520.758C683.241 520.605 682.934 520.452 682.321 520.452C681.708 520.452 681.248 520.605 680.788 520.758C680.328 520.911 679.867 521.218 679.561 521.678L678.181 519.992Z"
                            fill="#262626"/>
                        <path
                            d="M693.515 531.331C693.362 531.331 693.209 531.331 693.055 531.331C692.902 531.331 692.748 531.331 692.442 531.178C692.288 531.178 692.135 531.025 691.828 531.025C691.522 531.025 691.522 530.871 691.368 530.718L691.828 528.266C691.982 528.266 692.135 528.42 692.288 528.573C692.442 528.726 692.749 528.726 693.055 528.726C693.362 528.726 693.669 528.573 693.822 528.42C693.975 528.266 694.282 527.96 694.282 527.5L689.528 518H692.902L695.815 524.436L698.422 518H701.642L697.042 527.96C696.735 528.573 696.582 529.033 696.275 529.492C695.969 529.952 695.815 530.258 695.509 530.565C695.202 530.871 694.895 531.025 694.589 531.178C694.282 531.331 693.975 531.331 693.515 531.331Z"
                            fill="#262626"/>
                        <path
                            d="M710.996 518.153H713.91V531.178H710.996V526.275C710.69 526.428 710.23 526.581 709.769 526.581C709.309 526.734 708.849 526.734 708.083 526.734C707.316 526.734 706.703 526.581 706.089 526.428C705.629 526.275 705.169 525.815 704.862 525.508C704.556 525.049 704.249 524.589 704.096 524.129C703.942 523.516 703.942 523.057 703.942 522.291V518.153H706.856V521.371C706.856 522.291 707.009 522.903 707.316 523.363C707.623 523.823 708.236 523.976 709.003 523.976C709.463 523.976 709.769 523.976 710.076 523.823C710.383 523.67 710.69 523.67 710.843 523.516V518.153H710.996Z"
                            fill="#262626"/>
                        <path
                            d="M727.557 528.573V531.178H718.356V518.153H727.404V520.758H721.423V523.363H726.637V525.815H721.423V528.573H727.557Z"
                            fill="#262626"/>
                        <path
                            d="M742.584 518.153V531.178H739.518V525.815H734.304V531.178H731.237V518.153H734.304V523.21H739.518V518.153H742.584Z"
                            fill="#262626"/>
                        <path
                            d="M752.705 531.331C751.785 531.331 750.865 531.178 750.098 530.718C749.331 530.412 748.565 529.799 748.105 529.186C747.491 528.573 747.031 527.807 746.725 527.041C746.418 526.274 746.265 525.355 746.265 524.589C746.265 523.67 746.418 522.903 746.725 522.137C747.031 521.371 747.491 520.605 748.105 519.992C748.718 519.379 749.331 518.919 750.098 518.613C750.865 518.306 751.785 518 752.705 518C753.625 518 754.545 518.153 755.312 518.613C756.079 518.919 756.845 519.532 757.305 520.145C757.919 520.758 758.379 521.524 758.685 522.29C758.992 523.057 759.145 523.823 759.145 524.742C759.145 525.662 758.992 526.428 758.685 527.194C758.379 527.96 757.919 528.726 757.305 529.339C756.692 529.952 756.079 530.412 755.312 530.871C754.545 531.178 753.625 531.331 752.705 531.331ZM749.331 524.742C749.331 525.202 749.332 525.662 749.485 526.274C749.638 526.734 749.792 527.194 750.098 527.5C750.405 527.807 750.712 528.113 751.172 528.42C751.632 528.573 752.092 528.726 752.705 528.726C753.318 528.726 753.778 528.573 754.238 528.42C754.698 528.266 755.005 527.96 755.312 527.5C755.619 527.194 755.772 526.734 755.925 526.274C756.079 525.815 756.079 525.355 756.079 524.895C756.079 524.436 756.079 523.976 755.925 523.363C755.772 522.903 755.619 522.444 755.312 522.137C755.005 521.831 754.698 521.524 754.238 521.218C753.778 521.065 753.318 520.911 752.705 520.911C752.092 520.911 751.632 521.065 751.172 521.218C750.712 521.371 750.405 521.678 750.098 522.137C749.792 522.444 749.638 522.903 749.485 523.363C749.485 523.823 749.331 524.282 749.331 524.742Z"
                            fill="#262626"/>
                        <path
                            d="M700.082 276.962C700.082 303.624 678.46 325.23 651.779 325.23C625.098 325.23 603.477 303.624 603.477 276.962C603.477 250.3 625.098 228.695 651.779 228.695C678.46 228.695 700.082 250.3 700.082 276.962Z"
                            fill="#38B563"/>
                        <path
                            d="M646.872 294.89C645.799 294.89 644.725 294.431 644.112 293.665L630.925 278.801C629.544 277.269 629.698 274.971 631.231 273.591C632.765 272.212 635.065 272.366 636.445 273.898L647.025 285.85L672.327 261.18C673.707 259.801 676.16 259.801 677.54 261.18C678.92 262.559 678.92 265.011 677.54 266.39L649.479 293.818C648.865 294.431 647.945 294.89 646.872 294.89Z"
                            fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="631" height="555" fill="white" transform="translate(164 191)"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
        <Keypress key-event="keyup" :key-code="13" @success="onNext"/>
    </div>
</template>

<script>
    import ProgressBarSteps from "./ProgressBarStep";

    export default {
        name: "LearnStep",
        components: {
            ProgressBarSteps,
            Keypress: () => import('vue-keypress')
        },
        methods: {
            onNext() {
                this.$emit('next');
            },
            onPrev() {
                this.$emit('prev');
            }
        },
        async mounted() {
            await this.$metrika.reachGoal('learn-step');
        }
    }
</script>

<style lang="scss" scoped>
    .learn {
        &__img {
            width: 100%;
            max-width: 735px;
        }
    }
</style>
